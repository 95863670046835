// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  propertyIdentifier = 121,
  identifier = 122,
  nameIdentifier = 123,
  insertSemi = 124,
  LineComment = 1,
  BlockComment = 2,
  Expression = 3,
  expression0 = 128,
  ForExpression = 4,
  forExpressionStart = 131,
  _for = 5,
  ForInExpressions = 6,
  ForInExpression = 7,
  Name = 8,
  Identifier = 9,
  AdditionalIdentifier = 10,
  minus = 11,
  plus = 12,
  divide = 13,
  multi = 14,
  exp = 15,
  inExpressionSep = 136,
  _in = 16,
  IterationContext = 17,
  forExpressionBodyStart = 139,
  _return = 18,
  IfExpression = 19,
  ifExpressionStart = 140,
  _if = 20,
  then = 21,
  _else = 22,
  QuantifiedExpression = 23,
  quantifiedExpressionStart = 141,
  some = 24,
  every = 25,
  QuantifiedInExpressions = 26,
  QuantifiedInExpression = 27,
  satisfies = 28,
  Disjunction = 29,
  or = 30,
  Conjunction = 31,
  and = 32,
  Comparison = 33,
  between = 36,
  PositiveUnaryTest = 37,
  PositiveUnaryTests = 39,
  ArithmeticExpression = 41,
  arithmeticPlusStart = 145,
  arithmeticTimesStart = 146,
  arithmeticExpStart = 147,
  arithmeticUnaryStart = 148,
  InstanceOfExpression = 42,
  instance = 43,
  of = 44,
  Type = 45,
  QualifiedName = 46,
  VariableName = 47,
  BacktickIdentifier = 48,
  SpecialType = 49,
  days = 50,
  time = 51,
  duration = 52,
  years = 53,
  months = 54,
  date = 55,
  list = 58,
  context = 61,
  ContextEntryTypes = 62,
  ContextEntryType = 63,
  _function = 65,
  ArgumentTypes = 66,
  ArgumentType = 67,
  PathExpression = 68,
  pathExpressionStart = 154,
  FilterExpression = 70,
  filterExpressionStart = 155,
  FunctionInvocation = 72,
  functionInvocationStart = 156,
  SpecialFunctionName = 73,
  functionInvocationParameters = 157,
  NamedParameters = 74,
  NamedParameter = 75,
  ParameterName = 76,
  PositionalParameters = 77,
  literal = 160,
  nil = 161,
  _null = 78,
  NumericLiteral = 79,
  StringLiteral = 80,
  BooleanLiteral = 81,
  DateTimeLiteral = 82,
  DateTimeConstructor = 83,
  AtLiteral = 84,
  SimplePositiveUnaryTest = 86,
  Interval = 87,
  listStart = 167,
  ParenthesizedExpression = 88,
  List = 89,
  FunctionDefinition = 90,
  functionDefinitionStart = 169,
  FormalParameters = 91,
  FormalParameter = 92,
  external = 93,
  Context = 97,
  contextStart = 171,
  ContextEntry = 98,
  Key = 99,
  PropertyName = 100,
  PropertyIdentifier = 101,
  Expressions = 102,
  UnaryTests = 103,
  not = 105,
  Dialect_camunda = 0
