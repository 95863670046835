// source: automab.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.automab.ClientMessage', null, global);
goog.exportSymbol('proto.automab.Experiment', null, global);
goog.exportSymbol('proto.automab.ExperimentRun', null, global);
goog.exportSymbol('proto.automab.ReagentPlanned', null, global);
goog.exportSymbol('proto.automab.SampleInfo', null, global);
goog.exportSymbol('proto.automab.ServerMessage', null, global);
goog.exportSymbol('proto.automab.SessionData', null, global);
goog.exportSymbol('proto.automab.Status', null, global);
goog.exportSymbol('proto.automab.ToAutomabClient', null, global);
goog.exportSymbol('proto.automab.ToAutomabClient.ContentCase', null, global);
goog.exportSymbol('proto.automab.ToAutomabServer', null, global);
goog.exportSymbol('proto.automab.ToAutomabServer.ContentCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automab.ToAutomabServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.automab.ToAutomabServer.oneofGroups_);
};
goog.inherits(proto.automab.ToAutomabServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automab.ToAutomabServer.displayName = 'proto.automab.ToAutomabServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automab.ToAutomabClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.automab.ToAutomabClient.oneofGroups_);
};
goog.inherits(proto.automab.ToAutomabClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automab.ToAutomabClient.displayName = 'proto.automab.ToAutomabClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automab.ClientMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automab.ClientMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automab.ClientMessage.displayName = 'proto.automab.ClientMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automab.ServerMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automab.ServerMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automab.ServerMessage.displayName = 'proto.automab.ServerMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automab.ExperimentRun = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automab.ExperimentRun, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automab.ExperimentRun.displayName = 'proto.automab.ExperimentRun';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automab.SampleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automab.SampleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automab.SampleInfo.displayName = 'proto.automab.SampleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automab.ReagentPlanned = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automab.ReagentPlanned, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automab.ReagentPlanned.displayName = 'proto.automab.ReagentPlanned';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automab.Experiment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automab.Experiment.repeatedFields_, null);
};
goog.inherits(proto.automab.Experiment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automab.Experiment.displayName = 'proto.automab.Experiment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automab.SessionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automab.SessionData.repeatedFields_, null);
};
goog.inherits(proto.automab.SessionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automab.SessionData.displayName = 'proto.automab.SessionData';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.automab.ToAutomabServer.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.automab.ToAutomabServer.ContentCase = {
  CONTENT_NOT_SET: 0,
  MESSAGE: 1
};

/**
 * @return {proto.automab.ToAutomabServer.ContentCase}
 */
proto.automab.ToAutomabServer.prototype.getContentCase = function() {
  return /** @type {proto.automab.ToAutomabServer.ContentCase} */(jspb.Message.computeOneofCase(this, proto.automab.ToAutomabServer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automab.ToAutomabServer.prototype.toObject = function(opt_includeInstance) {
  return proto.automab.ToAutomabServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automab.ToAutomabServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ToAutomabServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && proto.automab.ClientMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automab.ToAutomabServer}
 */
proto.automab.ToAutomabServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automab.ToAutomabServer;
  return proto.automab.ToAutomabServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automab.ToAutomabServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automab.ToAutomabServer}
 */
proto.automab.ToAutomabServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.automab.ClientMessage;
      reader.readMessage(value,proto.automab.ClientMessage.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automab.ToAutomabServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automab.ToAutomabServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automab.ToAutomabServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ToAutomabServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.automab.ClientMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional ClientMessage message = 1;
 * @return {?proto.automab.ClientMessage}
 */
proto.automab.ToAutomabServer.prototype.getMessage = function() {
  return /** @type{?proto.automab.ClientMessage} */ (
    jspb.Message.getWrapperField(this, proto.automab.ClientMessage, 1));
};


/**
 * @param {?proto.automab.ClientMessage|undefined} value
 * @return {!proto.automab.ToAutomabServer} returns this
*/
proto.automab.ToAutomabServer.prototype.setMessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.automab.ToAutomabServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automab.ToAutomabServer} returns this
 */
proto.automab.ToAutomabServer.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automab.ToAutomabServer.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.automab.ToAutomabClient.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.automab.ToAutomabClient.ContentCase = {
  CONTENT_NOT_SET: 0,
  MESSAGE: 1,
  SESSION: 2
};

/**
 * @return {proto.automab.ToAutomabClient.ContentCase}
 */
proto.automab.ToAutomabClient.prototype.getContentCase = function() {
  return /** @type {proto.automab.ToAutomabClient.ContentCase} */(jspb.Message.computeOneofCase(this, proto.automab.ToAutomabClient.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automab.ToAutomabClient.prototype.toObject = function(opt_includeInstance) {
  return proto.automab.ToAutomabClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automab.ToAutomabClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ToAutomabClient.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && proto.automab.ServerMessage.toObject(includeInstance, f),
    session: (f = msg.getSession()) && proto.automab.SessionData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automab.ToAutomabClient}
 */
proto.automab.ToAutomabClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automab.ToAutomabClient;
  return proto.automab.ToAutomabClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automab.ToAutomabClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automab.ToAutomabClient}
 */
proto.automab.ToAutomabClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.automab.ServerMessage;
      reader.readMessage(value,proto.automab.ServerMessage.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.automab.SessionData;
      reader.readMessage(value,proto.automab.SessionData.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automab.ToAutomabClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automab.ToAutomabClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automab.ToAutomabClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ToAutomabClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.automab.ServerMessage.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.automab.SessionData.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServerMessage message = 1;
 * @return {?proto.automab.ServerMessage}
 */
proto.automab.ToAutomabClient.prototype.getMessage = function() {
  return /** @type{?proto.automab.ServerMessage} */ (
    jspb.Message.getWrapperField(this, proto.automab.ServerMessage, 1));
};


/**
 * @param {?proto.automab.ServerMessage|undefined} value
 * @return {!proto.automab.ToAutomabClient} returns this
*/
proto.automab.ToAutomabClient.prototype.setMessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.automab.ToAutomabClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automab.ToAutomabClient} returns this
 */
proto.automab.ToAutomabClient.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automab.ToAutomabClient.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SessionData session = 2;
 * @return {?proto.automab.SessionData}
 */
proto.automab.ToAutomabClient.prototype.getSession = function() {
  return /** @type{?proto.automab.SessionData} */ (
    jspb.Message.getWrapperField(this, proto.automab.SessionData, 2));
};


/**
 * @param {?proto.automab.SessionData|undefined} value
 * @return {!proto.automab.ToAutomabClient} returns this
*/
proto.automab.ToAutomabClient.prototype.setSession = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.automab.ToAutomabClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automab.ToAutomabClient} returns this
 */
proto.automab.ToAutomabClient.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automab.ToAutomabClient.prototype.hasSession = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automab.ClientMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.automab.ClientMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automab.ClientMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ClientMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    payload: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automab.ClientMessage}
 */
proto.automab.ClientMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automab.ClientMessage;
  return proto.automab.ClientMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automab.ClientMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automab.ClientMessage}
 */
proto.automab.ClientMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automab.ClientMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automab.ClientMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automab.ClientMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ClientMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.automab.ClientMessage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.ClientMessage} returns this
 */
proto.automab.ClientMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payload = 2;
 * @return {string}
 */
proto.automab.ClientMessage.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.ClientMessage} returns this
 */
proto.automab.ClientMessage.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automab.ServerMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.automab.ServerMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automab.ServerMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ServerMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    payload: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automab.ServerMessage}
 */
proto.automab.ServerMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automab.ServerMessage;
  return proto.automab.ServerMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automab.ServerMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automab.ServerMessage}
 */
proto.automab.ServerMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automab.ServerMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automab.ServerMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automab.ServerMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ServerMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.automab.ServerMessage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.ServerMessage} returns this
 */
proto.automab.ServerMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payload = 2;
 * @return {string}
 */
proto.automab.ServerMessage.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.ServerMessage} returns this
 */
proto.automab.ServerMessage.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automab.ExperimentRun.prototype.toObject = function(opt_includeInstance) {
  return proto.automab.ExperimentRun.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automab.ExperimentRun} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ExperimentRun.toObject = function(includeInstance, msg) {
  var f, obj = {
    plannedsteps: jspb.Message.getFieldWithDefault(msg, 1, 0),
    completedsteps: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automab.ExperimentRun}
 */
proto.automab.ExperimentRun.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automab.ExperimentRun;
  return proto.automab.ExperimentRun.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automab.ExperimentRun} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automab.ExperimentRun}
 */
proto.automab.ExperimentRun.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlannedsteps(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedsteps(value);
      break;
    case 3:
      var value = /** @type {!proto.automab.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automab.ExperimentRun.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automab.ExperimentRun.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automab.ExperimentRun} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ExperimentRun.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlannedsteps();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompletedsteps();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 plannedSteps = 1;
 * @return {number}
 */
proto.automab.ExperimentRun.prototype.getPlannedsteps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automab.ExperimentRun} returns this
 */
proto.automab.ExperimentRun.prototype.setPlannedsteps = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 completedSteps = 2;
 * @return {number}
 */
proto.automab.ExperimentRun.prototype.getCompletedsteps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automab.ExperimentRun} returns this
 */
proto.automab.ExperimentRun.prototype.setCompletedsteps = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Status status = 3;
 * @return {!proto.automab.Status}
 */
proto.automab.ExperimentRun.prototype.getStatus = function() {
  return /** @type {!proto.automab.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.automab.Status} value
 * @return {!proto.automab.ExperimentRun} returns this
 */
proto.automab.ExperimentRun.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automab.SampleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.automab.SampleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automab.SampleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.SampleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    source: jspb.Message.getFieldWithDefault(msg, 2, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 3, ""),
    preparationsteps: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automab.SampleInfo}
 */
proto.automab.SampleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automab.SampleInfo;
  return proto.automab.SampleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automab.SampleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automab.SampleInfo}
 */
proto.automab.SampleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreparationsteps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automab.SampleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automab.SampleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automab.SampleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.SampleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPreparationsteps();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.automab.SampleInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.SampleInfo} returns this
 */
proto.automab.SampleInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string source = 2;
 * @return {string}
 */
proto.automab.SampleInfo.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.SampleInfo} returns this
 */
proto.automab.SampleInfo.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string concentration = 3;
 * @return {string}
 */
proto.automab.SampleInfo.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.SampleInfo} returns this
 */
proto.automab.SampleInfo.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string preparationSteps = 4;
 * @return {string}
 */
proto.automab.SampleInfo.prototype.getPreparationsteps = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.SampleInfo} returns this
 */
proto.automab.SampleInfo.prototype.setPreparationsteps = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automab.ReagentPlanned.prototype.toObject = function(opt_includeInstance) {
  return proto.automab.ReagentPlanned.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automab.ReagentPlanned} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ReagentPlanned.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    concentration: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automab.ReagentPlanned}
 */
proto.automab.ReagentPlanned.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automab.ReagentPlanned;
  return proto.automab.ReagentPlanned.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automab.ReagentPlanned} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automab.ReagentPlanned}
 */
proto.automab.ReagentPlanned.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcentration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automab.ReagentPlanned.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automab.ReagentPlanned.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automab.ReagentPlanned} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.ReagentPlanned.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConcentration();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.automab.ReagentPlanned.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.ReagentPlanned} returns this
 */
proto.automab.ReagentPlanned.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string concentration = 2;
 * @return {string}
 */
proto.automab.ReagentPlanned.prototype.getConcentration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.ReagentPlanned} returns this
 */
proto.automab.ReagentPlanned.prototype.setConcentration = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string role = 3;
 * @return {string}
 */
proto.automab.ReagentPlanned.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.ReagentPlanned} returns this
 */
proto.automab.ReagentPlanned.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automab.Experiment.repeatedFields_ = [2,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automab.Experiment.prototype.toObject = function(opt_includeInstance) {
  return proto.automab.Experiment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automab.Experiment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.Experiment.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runsList: jspb.Message.toObjectList(msg.getRunsList(),
    proto.automab.ExperimentRun.toObject, includeInstance),
    currentrunindex: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    objective: jspb.Message.getFieldWithDefault(msg, 5, ""),
    plannedmethod: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sampleinfo: (f = msg.getSampleinfo()) && proto.automab.SampleInfo.toObject(includeInstance, f),
    reagentsplannedList: jspb.Message.toObjectList(msg.getReagentsplannedList(),
    proto.automab.ReagentPlanned.toObject, includeInstance),
    prerunnotes: jspb.Message.getFieldWithDefault(msg, 9, ""),
    automationscript: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automab.Experiment}
 */
proto.automab.Experiment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automab.Experiment;
  return proto.automab.Experiment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automab.Experiment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automab.Experiment}
 */
proto.automab.Experiment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.automab.ExperimentRun;
      reader.readMessage(value,proto.automab.ExperimentRun.deserializeBinaryFromReader);
      msg.addRuns(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentrunindex(value);
      break;
    case 4:
      var value = /** @type {!proto.automab.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjective(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlannedmethod(value);
      break;
    case 7:
      var value = new proto.automab.SampleInfo;
      reader.readMessage(value,proto.automab.SampleInfo.deserializeBinaryFromReader);
      msg.setSampleinfo(value);
      break;
    case 8:
      var value = new proto.automab.ReagentPlanned;
      reader.readMessage(value,proto.automab.ReagentPlanned.deserializeBinaryFromReader);
      msg.addReagentsplanned(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrerunnotes(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomationscript(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automab.Experiment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automab.Experiment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automab.Experiment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.Experiment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.automab.ExperimentRun.serializeBinaryToWriter
    );
  }
  f = message.getCurrentrunindex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getObjective();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlannedmethod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSampleinfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.automab.SampleInfo.serializeBinaryToWriter
    );
  }
  f = message.getReagentsplannedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.automab.ReagentPlanned.serializeBinaryToWriter
    );
  }
  f = message.getPrerunnotes();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAutomationscript();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.automab.Experiment.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ExperimentRun runs = 2;
 * @return {!Array<!proto.automab.ExperimentRun>}
 */
proto.automab.Experiment.prototype.getRunsList = function() {
  return /** @type{!Array<!proto.automab.ExperimentRun>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automab.ExperimentRun, 2));
};


/**
 * @param {!Array<!proto.automab.ExperimentRun>} value
 * @return {!proto.automab.Experiment} returns this
*/
proto.automab.Experiment.prototype.setRunsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.automab.ExperimentRun=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automab.ExperimentRun}
 */
proto.automab.Experiment.prototype.addRuns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.automab.ExperimentRun, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.clearRunsList = function() {
  return this.setRunsList([]);
};


/**
 * optional int32 currentRunIndex = 3;
 * @return {number}
 */
proto.automab.Experiment.prototype.getCurrentrunindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.setCurrentrunindex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.automab.Status}
 */
proto.automab.Experiment.prototype.getStatus = function() {
  return /** @type {!proto.automab.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.automab.Status} value
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string objective = 5;
 * @return {string}
 */
proto.automab.Experiment.prototype.getObjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.setObjective = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plannedMethod = 6;
 * @return {string}
 */
proto.automab.Experiment.prototype.getPlannedmethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.setPlannedmethod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional SampleInfo sampleInfo = 7;
 * @return {?proto.automab.SampleInfo}
 */
proto.automab.Experiment.prototype.getSampleinfo = function() {
  return /** @type{?proto.automab.SampleInfo} */ (
    jspb.Message.getWrapperField(this, proto.automab.SampleInfo, 7));
};


/**
 * @param {?proto.automab.SampleInfo|undefined} value
 * @return {!proto.automab.Experiment} returns this
*/
proto.automab.Experiment.prototype.setSampleinfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.clearSampleinfo = function() {
  return this.setSampleinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automab.Experiment.prototype.hasSampleinfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated ReagentPlanned reagentsPlanned = 8;
 * @return {!Array<!proto.automab.ReagentPlanned>}
 */
proto.automab.Experiment.prototype.getReagentsplannedList = function() {
  return /** @type{!Array<!proto.automab.ReagentPlanned>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automab.ReagentPlanned, 8));
};


/**
 * @param {!Array<!proto.automab.ReagentPlanned>} value
 * @return {!proto.automab.Experiment} returns this
*/
proto.automab.Experiment.prototype.setReagentsplannedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.automab.ReagentPlanned=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automab.ReagentPlanned}
 */
proto.automab.Experiment.prototype.addReagentsplanned = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.automab.ReagentPlanned, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.clearReagentsplannedList = function() {
  return this.setReagentsplannedList([]);
};


/**
 * optional string preRunNotes = 9;
 * @return {string}
 */
proto.automab.Experiment.prototype.getPrerunnotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.setPrerunnotes = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string automationScript = 10;
 * @return {string}
 */
proto.automab.Experiment.prototype.getAutomationscript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.Experiment} returns this
 */
proto.automab.Experiment.prototype.setAutomationscript = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automab.SessionData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automab.SessionData.prototype.toObject = function(opt_includeInstance) {
  return proto.automab.SessionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automab.SessionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.SessionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    experimentsList: jspb.Message.toObjectList(msg.getExperimentsList(),
    proto.automab.Experiment.toObject, includeInstance),
    currentexperimentindex: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automab.SessionData}
 */
proto.automab.SessionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automab.SessionData;
  return proto.automab.SessionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automab.SessionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automab.SessionData}
 */
proto.automab.SessionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.automab.Experiment;
      reader.readMessage(value,proto.automab.Experiment.deserializeBinaryFromReader);
      msg.addExperiments(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentexperimentindex(value);
      break;
    case 4:
      var value = /** @type {!proto.automab.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automab.SessionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automab.SessionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automab.SessionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automab.SessionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExperimentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.automab.Experiment.serializeBinaryToWriter
    );
  }
  f = message.getCurrentexperimentindex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.automab.SessionData.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.automab.SessionData} returns this
 */
proto.automab.SessionData.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Experiment experiments = 2;
 * @return {!Array<!proto.automab.Experiment>}
 */
proto.automab.SessionData.prototype.getExperimentsList = function() {
  return /** @type{!Array<!proto.automab.Experiment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automab.Experiment, 2));
};


/**
 * @param {!Array<!proto.automab.Experiment>} value
 * @return {!proto.automab.SessionData} returns this
*/
proto.automab.SessionData.prototype.setExperimentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.automab.Experiment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automab.Experiment}
 */
proto.automab.SessionData.prototype.addExperiments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.automab.Experiment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automab.SessionData} returns this
 */
proto.automab.SessionData.prototype.clearExperimentsList = function() {
  return this.setExperimentsList([]);
};


/**
 * optional int32 currentExperimentIndex = 3;
 * @return {number}
 */
proto.automab.SessionData.prototype.getCurrentexperimentindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automab.SessionData} returns this
 */
proto.automab.SessionData.prototype.setCurrentexperimentindex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.automab.Status}
 */
proto.automab.SessionData.prototype.getStatus = function() {
  return /** @type {!proto.automab.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.automab.Status} value
 * @return {!proto.automab.SessionData} returns this
 */
proto.automab.SessionData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.automab.Status = {
  NOTSTARTED: 0,
  INPROGRESS: 1,
  COMPLETED: 2
};

goog.object.extend(exports, proto.automab);
