<template>
    <div class="viewer-container">
        <div id="unityContainer" style="width: 960px; height: 600px; margin: auto"></div>
        <div class="search-string-display">{{ searchString }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue';

/* eslint-disable */
export default defineComponent({
    name: 'Viewer',
    props: {
        searchString: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const unityInstance = ref<null | { SendMessage: (gameObjectName: string, methodName: string, parameter: any) => void }>(null);

        const sendMessageToUnity = (gameObjectName: string, methodName: string, parameter: any) => {
            if (unityInstance.value && unityInstance.value.SendMessage) {
                unityInstance.value.SendMessage(gameObjectName, methodName, parameter);
            } else {
                console.error("Unity instance or SendMessage method not available.");
            }
        };

        onMounted(() => {
            const script = document.createElement("script");
            script.src = "Build/UnityLoader.js";
            script.onload = () => {
                unityInstance.value = (window as any).UnityLoader.instantiate("unityContainer", "Build/WebGL.json", { TOTAL_MEMORY: 1610612736 });
            };
            script.onerror = () => {
                console.error("Failed to load the Unity Loader script.");
            };
            document.body.appendChild(script);
        });

        watch(() => props.searchString, (newSearchString) => {
            sendMessageToUnity('LoadRbp', 'Clean', "");
            sendMessageToUnity('LoadRbp', 'ApplyName', newSearchString);
        });

        return {
            sendMessageToUnity
        };
    },
});
</script>

<style scoped>
.viewer-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.search-string-display {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}
</style>
