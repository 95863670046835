<template>
    <div class="nested-fields">
        <div v-for="fieldDef in fieldsMetadata" :key="fieldDef.name">
            <DynamicFormField :fieldDef="fieldDef" v-model:value="internalValue[fieldDef.name]" :isCollapsed="collapsedState[fieldDef.name]" @toggle-collapse="toggleCollapse(fieldDef.name)" />
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, ref } from 'vue';
import DynamicFormField from './DynamicFormField.vue';

const props = defineProps({
    value: {
        type: Object,
        default: () => ({})
    },
    fieldsMetadata: {
        type: Array,
        required: true
    }
});

const emit = defineEmits(['update:value']);

const collapsedState = ref({});

function toggleCollapse(fieldName) {
    collapsedState.value[fieldName] = !collapsedState.value[fieldName];
}

const internalValue = computed({
    get: () => props.value,
    set: val => emit('update:value', val)
});
</script>

<style scoped>
.nested-fields {
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 4px;
}

.nested-fields:hover > *:not(:hover) {
    background-color: transparent;
}

.nested-fields:hover {
    background-color: rgba(0, 120, 255, 0.1);
}
</style>