// source: game.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.game.Empty', null, global);
goog.exportSymbol('proto.game.Event', null, global);
goog.exportSymbol('proto.game.Exercise', null, global);
goog.exportSymbol('proto.game.ExerciseSet', null, global);
goog.exportSymbol('proto.game.ExerciseSetReport', null, global);
goog.exportSymbol('proto.game.LoginAck', null, global);
goog.exportSymbol('proto.game.LoginRequest', null, global);
goog.exportSymbol('proto.game.NamedMsgVals', null, global);
goog.exportSymbol('proto.game.NamedVals', null, global);
goog.exportSymbol('proto.game.NamedVals.NamedJsNtvParameter', null, global);
goog.exportSymbol('proto.game.ResetAck', null, global);
goog.exportSymbol('proto.game.SessionData', null, global);
goog.exportSymbol('proto.game.Status', null, global);
goog.exportSymbol('proto.game.ToGameClient', null, global);
goog.exportSymbol('proto.game.ToGameClient.TypeCase', null, global);
goog.exportSymbol('proto.game.ToGameServer', null, global);
goog.exportSymbol('proto.game.ToGameServer.ContentCase', null, global);
goog.exportSymbol('proto.game.ValUnion', null, global);
goog.exportSymbol('proto.game.ValUnion.ValCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.ToGameServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.game.ToGameServer.oneofGroups_);
};
goog.inherits(proto.game.ToGameServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.ToGameServer.displayName = 'proto.game.ToGameServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.ValUnion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.game.ValUnion.oneofGroups_);
};
goog.inherits(proto.game.ValUnion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.ValUnion.displayName = 'proto.game.ValUnion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.NamedMsgVals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.game.NamedMsgVals.repeatedFields_, null);
};
goog.inherits(proto.game.NamedMsgVals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.NamedMsgVals.displayName = 'proto.game.NamedMsgVals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.NamedVals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.game.NamedVals.repeatedFields_, null);
};
goog.inherits(proto.game.NamedVals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.NamedVals.displayName = 'proto.game.NamedVals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.NamedVals.NamedJsNtvParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.NamedVals.NamedJsNtvParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.NamedVals.NamedJsNtvParameter.displayName = 'proto.game.NamedVals.NamedJsNtvParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.ToGameClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.game.ToGameClient.oneofGroups_);
};
goog.inherits(proto.game.ToGameClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.ToGameClient.displayName = 'proto.game.ToGameClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.Empty.displayName = 'proto.game.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.Event.displayName = 'proto.game.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.LoginRequest.displayName = 'proto.game.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.LoginAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.LoginAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.LoginAck.displayName = 'proto.game.LoginAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.ResetAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.ResetAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.ResetAck.displayName = 'proto.game.ResetAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.ExerciseSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.ExerciseSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.ExerciseSet.displayName = 'proto.game.ExerciseSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.Exercise = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.game.Exercise.repeatedFields_, null);
};
goog.inherits(proto.game.Exercise, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.Exercise.displayName = 'proto.game.Exercise';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.SessionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.game.SessionData.repeatedFields_, null);
};
goog.inherits(proto.game.SessionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.SessionData.displayName = 'proto.game.SessionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.game.ExerciseSetReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.game.ExerciseSetReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.game.ExerciseSetReport.displayName = 'proto.game.ExerciseSetReport';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.game.ToGameServer.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.game.ToGameServer.ContentCase = {
  CONTENT_NOT_SET: 0,
  CPSH_NAMED_MSG_VALS: 1,
  CPSH_NAMED_VALS: 2,
  EXERCISE_SET_REPORT: 3,
  LOGIN_REQ: 4,
  RESET_REQ: 5,
  SET_STARTED: 6,
  SET_COMPLETED: 7
};

/**
 * @return {proto.game.ToGameServer.ContentCase}
 */
proto.game.ToGameServer.prototype.getContentCase = function() {
  return /** @type {proto.game.ToGameServer.ContentCase} */(jspb.Message.computeOneofCase(this, proto.game.ToGameServer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.ToGameServer.prototype.toObject = function(opt_includeInstance) {
  return proto.game.ToGameServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.ToGameServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ToGameServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpshNamedMsgVals: (f = msg.getCpshNamedMsgVals()) && proto.game.NamedMsgVals.toObject(includeInstance, f),
    cpshNamedVals: (f = msg.getCpshNamedVals()) && proto.game.NamedVals.toObject(includeInstance, f),
    exerciseSetReport: (f = msg.getExerciseSetReport()) && proto.game.ExerciseSetReport.toObject(includeInstance, f),
    loginReq: (f = msg.getLoginReq()) && proto.game.LoginRequest.toObject(includeInstance, f),
    resetReq: jspb.Message.getFieldWithDefault(msg, 5, ""),
    setStarted: (f = msg.getSetStarted()) && proto.game.Event.toObject(includeInstance, f),
    setCompleted: (f = msg.getSetCompleted()) && proto.game.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.ToGameServer}
 */
proto.game.ToGameServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.ToGameServer;
  return proto.game.ToGameServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.ToGameServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.ToGameServer}
 */
proto.game.ToGameServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.game.NamedMsgVals;
      reader.readMessage(value,proto.game.NamedMsgVals.deserializeBinaryFromReader);
      msg.setCpshNamedMsgVals(value);
      break;
    case 2:
      var value = new proto.game.NamedVals;
      reader.readMessage(value,proto.game.NamedVals.deserializeBinaryFromReader);
      msg.setCpshNamedVals(value);
      break;
    case 3:
      var value = new proto.game.ExerciseSetReport;
      reader.readMessage(value,proto.game.ExerciseSetReport.deserializeBinaryFromReader);
      msg.setExerciseSetReport(value);
      break;
    case 4:
      var value = new proto.game.LoginRequest;
      reader.readMessage(value,proto.game.LoginRequest.deserializeBinaryFromReader);
      msg.setLoginReq(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetReq(value);
      break;
    case 6:
      var value = new proto.game.Event;
      reader.readMessage(value,proto.game.Event.deserializeBinaryFromReader);
      msg.setSetStarted(value);
      break;
    case 7:
      var value = new proto.game.Event;
      reader.readMessage(value,proto.game.Event.deserializeBinaryFromReader);
      msg.setSetCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.ToGameServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.ToGameServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.ToGameServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ToGameServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCpshNamedMsgVals();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.game.NamedMsgVals.serializeBinaryToWriter
    );
  }
  f = message.getCpshNamedVals();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.game.NamedVals.serializeBinaryToWriter
    );
  }
  f = message.getExerciseSetReport();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.game.ExerciseSetReport.serializeBinaryToWriter
    );
  }
  f = message.getLoginReq();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.game.LoginRequest.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSetStarted();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.game.Event.serializeBinaryToWriter
    );
  }
  f = message.getSetCompleted();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.game.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional NamedMsgVals cpsh_named_msg_vals = 1;
 * @return {?proto.game.NamedMsgVals}
 */
proto.game.ToGameServer.prototype.getCpshNamedMsgVals = function() {
  return /** @type{?proto.game.NamedMsgVals} */ (
    jspb.Message.getWrapperField(this, proto.game.NamedMsgVals, 1));
};


/**
 * @param {?proto.game.NamedMsgVals|undefined} value
 * @return {!proto.game.ToGameServer} returns this
*/
proto.game.ToGameServer.prototype.setCpshNamedMsgVals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.game.ToGameServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameServer} returns this
 */
proto.game.ToGameServer.prototype.clearCpshNamedMsgVals = function() {
  return this.setCpshNamedMsgVals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameServer.prototype.hasCpshNamedMsgVals = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NamedVals cpsh_named_vals = 2;
 * @return {?proto.game.NamedVals}
 */
proto.game.ToGameServer.prototype.getCpshNamedVals = function() {
  return /** @type{?proto.game.NamedVals} */ (
    jspb.Message.getWrapperField(this, proto.game.NamedVals, 2));
};


/**
 * @param {?proto.game.NamedVals|undefined} value
 * @return {!proto.game.ToGameServer} returns this
*/
proto.game.ToGameServer.prototype.setCpshNamedVals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.game.ToGameServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameServer} returns this
 */
proto.game.ToGameServer.prototype.clearCpshNamedVals = function() {
  return this.setCpshNamedVals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameServer.prototype.hasCpshNamedVals = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExerciseSetReport exercise_set_report = 3;
 * @return {?proto.game.ExerciseSetReport}
 */
proto.game.ToGameServer.prototype.getExerciseSetReport = function() {
  return /** @type{?proto.game.ExerciseSetReport} */ (
    jspb.Message.getWrapperField(this, proto.game.ExerciseSetReport, 3));
};


/**
 * @param {?proto.game.ExerciseSetReport|undefined} value
 * @return {!proto.game.ToGameServer} returns this
*/
proto.game.ToGameServer.prototype.setExerciseSetReport = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.game.ToGameServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameServer} returns this
 */
proto.game.ToGameServer.prototype.clearExerciseSetReport = function() {
  return this.setExerciseSetReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameServer.prototype.hasExerciseSetReport = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LoginRequest login_req = 4;
 * @return {?proto.game.LoginRequest}
 */
proto.game.ToGameServer.prototype.getLoginReq = function() {
  return /** @type{?proto.game.LoginRequest} */ (
    jspb.Message.getWrapperField(this, proto.game.LoginRequest, 4));
};


/**
 * @param {?proto.game.LoginRequest|undefined} value
 * @return {!proto.game.ToGameServer} returns this
*/
proto.game.ToGameServer.prototype.setLoginReq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.game.ToGameServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameServer} returns this
 */
proto.game.ToGameServer.prototype.clearLoginReq = function() {
  return this.setLoginReq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameServer.prototype.hasLoginReq = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string reset_req = 5;
 * @return {string}
 */
proto.game.ToGameServer.prototype.getResetReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.ToGameServer} returns this
 */
proto.game.ToGameServer.prototype.setResetReq = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.game.ToGameServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ToGameServer} returns this
 */
proto.game.ToGameServer.prototype.clearResetReq = function() {
  return jspb.Message.setOneofField(this, 5, proto.game.ToGameServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameServer.prototype.hasResetReq = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Event set_started = 6;
 * @return {?proto.game.Event}
 */
proto.game.ToGameServer.prototype.getSetStarted = function() {
  return /** @type{?proto.game.Event} */ (
    jspb.Message.getWrapperField(this, proto.game.Event, 6));
};


/**
 * @param {?proto.game.Event|undefined} value
 * @return {!proto.game.ToGameServer} returns this
*/
proto.game.ToGameServer.prototype.setSetStarted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.game.ToGameServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameServer} returns this
 */
proto.game.ToGameServer.prototype.clearSetStarted = function() {
  return this.setSetStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameServer.prototype.hasSetStarted = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Event set_completed = 7;
 * @return {?proto.game.Event}
 */
proto.game.ToGameServer.prototype.getSetCompleted = function() {
  return /** @type{?proto.game.Event} */ (
    jspb.Message.getWrapperField(this, proto.game.Event, 7));
};


/**
 * @param {?proto.game.Event|undefined} value
 * @return {!proto.game.ToGameServer} returns this
*/
proto.game.ToGameServer.prototype.setSetCompleted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.game.ToGameServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameServer} returns this
 */
proto.game.ToGameServer.prototype.clearSetCompleted = function() {
  return this.setSetCompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameServer.prototype.hasSetCompleted = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.game.ValUnion.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.game.ValUnion.ValCase = {
  VAL_NOT_SET: 0,
  STR_VAL: 1,
  BIN_VAL: 2,
  NUM_VAL: 3,
  U32_VAL: 4,
  S32_VAL: 5,
  BOOL_VAL: 6
};

/**
 * @return {proto.game.ValUnion.ValCase}
 */
proto.game.ValUnion.prototype.getValCase = function() {
  return /** @type {proto.game.ValUnion.ValCase} */(jspb.Message.computeOneofCase(this, proto.game.ValUnion.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.ValUnion.prototype.toObject = function(opt_includeInstance) {
  return proto.game.ValUnion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.ValUnion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ValUnion.toObject = function(includeInstance, msg) {
  var f, obj = {
    strVal: jspb.Message.getFieldWithDefault(msg, 1, ""),
    binVal: msg.getBinVal_asB64(),
    numVal: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    u32Val: jspb.Message.getFieldWithDefault(msg, 4, 0),
    s32Val: jspb.Message.getFieldWithDefault(msg, 5, 0),
    boolVal: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.ValUnion}
 */
proto.game.ValUnion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.ValUnion;
  return proto.game.ValUnion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.ValUnion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.ValUnion}
 */
proto.game.ValUnion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrVal(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBinVal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNumVal(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setU32Val(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setS32Val(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolVal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.ValUnion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.ValUnion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.ValUnion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ValUnion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string str_val = 1;
 * @return {string}
 */
proto.game.ValUnion.prototype.getStrVal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.setStrVal = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.game.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.clearStrVal = function() {
  return jspb.Message.setOneofField(this, 1, proto.game.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ValUnion.prototype.hasStrVal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes bin_val = 2;
 * @return {!(string|Uint8Array)}
 */
proto.game.ValUnion.prototype.getBinVal = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes bin_val = 2;
 * This is a type-conversion wrapper around `getBinVal()`
 * @return {string}
 */
proto.game.ValUnion.prototype.getBinVal_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBinVal()));
};


/**
 * optional bytes bin_val = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBinVal()`
 * @return {!Uint8Array}
 */
proto.game.ValUnion.prototype.getBinVal_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBinVal()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.setBinVal = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.game.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.clearBinVal = function() {
  return jspb.Message.setOneofField(this, 2, proto.game.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ValUnion.prototype.hasBinVal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double num_val = 3;
 * @return {number}
 */
proto.game.ValUnion.prototype.getNumVal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.setNumVal = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.game.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.clearNumVal = function() {
  return jspb.Message.setOneofField(this, 3, proto.game.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ValUnion.prototype.hasNumVal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 u32_val = 4;
 * @return {number}
 */
proto.game.ValUnion.prototype.getU32Val = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.setU32Val = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.game.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.clearU32Val = function() {
  return jspb.Message.setOneofField(this, 4, proto.game.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ValUnion.prototype.hasU32Val = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional sint32 s32_val = 5;
 * @return {number}
 */
proto.game.ValUnion.prototype.getS32Val = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.setS32Val = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.game.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.clearS32Val = function() {
  return jspb.Message.setOneofField(this, 5, proto.game.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ValUnion.prototype.hasS32Val = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool bool_val = 6;
 * @return {boolean}
 */
proto.game.ValUnion.prototype.getBoolVal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.setBoolVal = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.game.ValUnion.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ValUnion} returns this
 */
proto.game.ValUnion.prototype.clearBoolVal = function() {
  return jspb.Message.setOneofField(this, 6, proto.game.ValUnion.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ValUnion.prototype.hasBoolVal = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.game.NamedMsgVals.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.NamedMsgVals.prototype.toObject = function(opt_includeInstance) {
  return proto.game.NamedMsgVals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.NamedMsgVals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.NamedMsgVals.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valsList: jspb.Message.toObjectList(msg.getValsList(),
    proto.game.ValUnion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.NamedMsgVals}
 */
proto.game.NamedMsgVals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.NamedMsgVals;
  return proto.game.NamedMsgVals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.NamedMsgVals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.NamedMsgVals}
 */
proto.game.NamedMsgVals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.game.ValUnion;
      reader.readMessage(value,proto.game.ValUnion.deserializeBinaryFromReader);
      msg.addVals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.NamedMsgVals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.NamedMsgVals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.NamedMsgVals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.NamedMsgVals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.game.ValUnion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.game.NamedMsgVals.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.NamedMsgVals} returns this
 */
proto.game.NamedMsgVals.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ValUnion vals = 2;
 * @return {!Array<!proto.game.ValUnion>}
 */
proto.game.NamedMsgVals.prototype.getValsList = function() {
  return /** @type{!Array<!proto.game.ValUnion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.game.ValUnion, 2));
};


/**
 * @param {!Array<!proto.game.ValUnion>} value
 * @return {!proto.game.NamedMsgVals} returns this
*/
proto.game.NamedMsgVals.prototype.setValsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.game.ValUnion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.game.ValUnion}
 */
proto.game.NamedMsgVals.prototype.addVals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.game.ValUnion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.game.NamedMsgVals} returns this
 */
proto.game.NamedMsgVals.prototype.clearValsList = function() {
  return this.setValsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.game.NamedVals.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.NamedVals.prototype.toObject = function(opt_includeInstance) {
  return proto.game.NamedVals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.NamedVals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.NamedVals.toObject = function(includeInstance, msg) {
  var f, obj = {
    npList: jspb.Message.toObjectList(msg.getNpList(),
    proto.game.NamedVals.NamedJsNtvParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.NamedVals}
 */
proto.game.NamedVals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.NamedVals;
  return proto.game.NamedVals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.NamedVals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.NamedVals}
 */
proto.game.NamedVals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.game.NamedVals.NamedJsNtvParameter;
      reader.readMessage(value,proto.game.NamedVals.NamedJsNtvParameter.deserializeBinaryFromReader);
      msg.addNp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.NamedVals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.NamedVals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.NamedVals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.NamedVals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNpList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.game.NamedVals.NamedJsNtvParameter.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.NamedVals.NamedJsNtvParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.game.NamedVals.NamedJsNtvParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.NamedVals.NamedJsNtvParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.NamedVals.NamedJsNtvParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    val: (f = msg.getVal()) && proto.game.ValUnion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.NamedVals.NamedJsNtvParameter}
 */
proto.game.NamedVals.NamedJsNtvParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.NamedVals.NamedJsNtvParameter;
  return proto.game.NamedVals.NamedJsNtvParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.NamedVals.NamedJsNtvParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.NamedVals.NamedJsNtvParameter}
 */
proto.game.NamedVals.NamedJsNtvParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.game.ValUnion;
      reader.readMessage(value,proto.game.ValUnion.deserializeBinaryFromReader);
      msg.setVal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.NamedVals.NamedJsNtvParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.NamedVals.NamedJsNtvParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.NamedVals.NamedJsNtvParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.NamedVals.NamedJsNtvParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.game.ValUnion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.game.NamedVals.NamedJsNtvParameter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.NamedVals.NamedJsNtvParameter} returns this
 */
proto.game.NamedVals.NamedJsNtvParameter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ValUnion val = 2;
 * @return {?proto.game.ValUnion}
 */
proto.game.NamedVals.NamedJsNtvParameter.prototype.getVal = function() {
  return /** @type{?proto.game.ValUnion} */ (
    jspb.Message.getWrapperField(this, proto.game.ValUnion, 2));
};


/**
 * @param {?proto.game.ValUnion|undefined} value
 * @return {!proto.game.NamedVals.NamedJsNtvParameter} returns this
*/
proto.game.NamedVals.NamedJsNtvParameter.prototype.setVal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.NamedVals.NamedJsNtvParameter} returns this
 */
proto.game.NamedVals.NamedJsNtvParameter.prototype.clearVal = function() {
  return this.setVal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.NamedVals.NamedJsNtvParameter.prototype.hasVal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated NamedJsNtvParameter np = 1;
 * @return {!Array<!proto.game.NamedVals.NamedJsNtvParameter>}
 */
proto.game.NamedVals.prototype.getNpList = function() {
  return /** @type{!Array<!proto.game.NamedVals.NamedJsNtvParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.game.NamedVals.NamedJsNtvParameter, 1));
};


/**
 * @param {!Array<!proto.game.NamedVals.NamedJsNtvParameter>} value
 * @return {!proto.game.NamedVals} returns this
*/
proto.game.NamedVals.prototype.setNpList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.game.NamedVals.NamedJsNtvParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.game.NamedVals.NamedJsNtvParameter}
 */
proto.game.NamedVals.prototype.addNp = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.game.NamedVals.NamedJsNtvParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.game.NamedVals} returns this
 */
proto.game.NamedVals.prototype.clearNpList = function() {
  return this.setNpList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.game.ToGameClient.oneofGroups_ = [[1,2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.game.ToGameClient.TypeCase = {
  TYPE_NOT_SET: 0,
  SPSH_NAMED_MSG_VALS: 1,
  SPSH_NAMED_VALS: 2,
  SESSION_DATA: 3,
  TASK_START: 4,
  TASK_COMPLETE: 5,
  EXERCISE_START: 6,
  SET_START: 7,
  LOGIN_ACK: 8,
  RESET_ACK: 9
};

/**
 * @return {proto.game.ToGameClient.TypeCase}
 */
proto.game.ToGameClient.prototype.getTypeCase = function() {
  return /** @type {proto.game.ToGameClient.TypeCase} */(jspb.Message.computeOneofCase(this, proto.game.ToGameClient.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.ToGameClient.prototype.toObject = function(opt_includeInstance) {
  return proto.game.ToGameClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.ToGameClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ToGameClient.toObject = function(includeInstance, msg) {
  var f, obj = {
    spshNamedMsgVals: (f = msg.getSpshNamedMsgVals()) && proto.game.NamedMsgVals.toObject(includeInstance, f),
    spshNamedVals: (f = msg.getSpshNamedVals()) && proto.game.NamedVals.toObject(includeInstance, f),
    sessionData: (f = msg.getSessionData()) && proto.game.SessionData.toObject(includeInstance, f),
    taskStart: jspb.Message.getFieldWithDefault(msg, 4, ""),
    taskComplete: jspb.Message.getFieldWithDefault(msg, 5, ""),
    exerciseStart: jspb.Message.getFieldWithDefault(msg, 6, ""),
    setStart: jspb.Message.getFieldWithDefault(msg, 7, ""),
    loginAck: (f = msg.getLoginAck()) && proto.game.LoginAck.toObject(includeInstance, f),
    resetAck: (f = msg.getResetAck()) && proto.game.ResetAck.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.ToGameClient}
 */
proto.game.ToGameClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.ToGameClient;
  return proto.game.ToGameClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.ToGameClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.ToGameClient}
 */
proto.game.ToGameClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.game.NamedMsgVals;
      reader.readMessage(value,proto.game.NamedMsgVals.deserializeBinaryFromReader);
      msg.setSpshNamedMsgVals(value);
      break;
    case 2:
      var value = new proto.game.NamedVals;
      reader.readMessage(value,proto.game.NamedVals.deserializeBinaryFromReader);
      msg.setSpshNamedVals(value);
      break;
    case 3:
      var value = new proto.game.SessionData;
      reader.readMessage(value,proto.game.SessionData.deserializeBinaryFromReader);
      msg.setSessionData(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskStart(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskComplete(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExerciseStart(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetStart(value);
      break;
    case 8:
      var value = new proto.game.LoginAck;
      reader.readMessage(value,proto.game.LoginAck.deserializeBinaryFromReader);
      msg.setLoginAck(value);
      break;
    case 9:
      var value = new proto.game.ResetAck;
      reader.readMessage(value,proto.game.ResetAck.deserializeBinaryFromReader);
      msg.setResetAck(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.ToGameClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.ToGameClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.ToGameClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ToGameClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpshNamedMsgVals();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.game.NamedMsgVals.serializeBinaryToWriter
    );
  }
  f = message.getSpshNamedVals();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.game.NamedVals.serializeBinaryToWriter
    );
  }
  f = message.getSessionData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.game.SessionData.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLoginAck();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.game.LoginAck.serializeBinaryToWriter
    );
  }
  f = message.getResetAck();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.game.ResetAck.serializeBinaryToWriter
    );
  }
};


/**
 * optional NamedMsgVals spsh_named_msg_vals = 1;
 * @return {?proto.game.NamedMsgVals}
 */
proto.game.ToGameClient.prototype.getSpshNamedMsgVals = function() {
  return /** @type{?proto.game.NamedMsgVals} */ (
    jspb.Message.getWrapperField(this, proto.game.NamedMsgVals, 1));
};


/**
 * @param {?proto.game.NamedMsgVals|undefined} value
 * @return {!proto.game.ToGameClient} returns this
*/
proto.game.ToGameClient.prototype.setSpshNamedMsgVals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.game.ToGameClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.clearSpshNamedMsgVals = function() {
  return this.setSpshNamedMsgVals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameClient.prototype.hasSpshNamedMsgVals = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NamedVals spsh_named_vals = 2;
 * @return {?proto.game.NamedVals}
 */
proto.game.ToGameClient.prototype.getSpshNamedVals = function() {
  return /** @type{?proto.game.NamedVals} */ (
    jspb.Message.getWrapperField(this, proto.game.NamedVals, 2));
};


/**
 * @param {?proto.game.NamedVals|undefined} value
 * @return {!proto.game.ToGameClient} returns this
*/
proto.game.ToGameClient.prototype.setSpshNamedVals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.game.ToGameClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.clearSpshNamedVals = function() {
  return this.setSpshNamedVals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameClient.prototype.hasSpshNamedVals = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SessionData session_data = 3;
 * @return {?proto.game.SessionData}
 */
proto.game.ToGameClient.prototype.getSessionData = function() {
  return /** @type{?proto.game.SessionData} */ (
    jspb.Message.getWrapperField(this, proto.game.SessionData, 3));
};


/**
 * @param {?proto.game.SessionData|undefined} value
 * @return {!proto.game.ToGameClient} returns this
*/
proto.game.ToGameClient.prototype.setSessionData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.game.ToGameClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.clearSessionData = function() {
  return this.setSessionData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameClient.prototype.hasSessionData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string task_start = 4;
 * @return {string}
 */
proto.game.ToGameClient.prototype.getTaskStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.setTaskStart = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.game.ToGameClient.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.clearTaskStart = function() {
  return jspb.Message.setOneofField(this, 4, proto.game.ToGameClient.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameClient.prototype.hasTaskStart = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string task_complete = 5;
 * @return {string}
 */
proto.game.ToGameClient.prototype.getTaskComplete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.setTaskComplete = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.game.ToGameClient.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.clearTaskComplete = function() {
  return jspb.Message.setOneofField(this, 5, proto.game.ToGameClient.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameClient.prototype.hasTaskComplete = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string exercise_start = 6;
 * @return {string}
 */
proto.game.ToGameClient.prototype.getExerciseStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.setExerciseStart = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.game.ToGameClient.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.clearExerciseStart = function() {
  return jspb.Message.setOneofField(this, 6, proto.game.ToGameClient.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameClient.prototype.hasExerciseStart = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string set_start = 7;
 * @return {string}
 */
proto.game.ToGameClient.prototype.getSetStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.setSetStart = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.game.ToGameClient.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.clearSetStart = function() {
  return jspb.Message.setOneofField(this, 7, proto.game.ToGameClient.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameClient.prototype.hasSetStart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LoginAck login_ack = 8;
 * @return {?proto.game.LoginAck}
 */
proto.game.ToGameClient.prototype.getLoginAck = function() {
  return /** @type{?proto.game.LoginAck} */ (
    jspb.Message.getWrapperField(this, proto.game.LoginAck, 8));
};


/**
 * @param {?proto.game.LoginAck|undefined} value
 * @return {!proto.game.ToGameClient} returns this
*/
proto.game.ToGameClient.prototype.setLoginAck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.game.ToGameClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.clearLoginAck = function() {
  return this.setLoginAck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameClient.prototype.hasLoginAck = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ResetAck reset_ack = 9;
 * @return {?proto.game.ResetAck}
 */
proto.game.ToGameClient.prototype.getResetAck = function() {
  return /** @type{?proto.game.ResetAck} */ (
    jspb.Message.getWrapperField(this, proto.game.ResetAck, 9));
};


/**
 * @param {?proto.game.ResetAck|undefined} value
 * @return {!proto.game.ToGameClient} returns this
*/
proto.game.ToGameClient.prototype.setResetAck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.game.ToGameClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ToGameClient} returns this
 */
proto.game.ToGameClient.prototype.clearResetAck = function() {
  return this.setResetAck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ToGameClient.prototype.hasResetAck = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.game.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.Empty}
 */
proto.game.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.Empty;
  return proto.game.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.Empty}
 */
proto.game.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.game.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.Event}
 */
proto.game.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.Event;
  return proto.game.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.Event}
 */
proto.game.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string info = 1;
 * @return {string}
 */
proto.game.Event.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.Event} returns this
 */
proto.game.Event.prototype.setInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.game.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    openaiApiKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    chatModel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    realtimeModel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    creqId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.LoginRequest}
 */
proto.game.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.LoginRequest;
  return proto.game.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.LoginRequest}
 */
proto.game.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenaiApiKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatModel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRealtimeModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpenaiApiKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChatModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRealtimeModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreqId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.game.LoginRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.LoginRequest} returns this
 */
proto.game.LoginRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string openai_api_key = 2;
 * @return {string}
 */
proto.game.LoginRequest.prototype.getOpenaiApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.LoginRequest} returns this
 */
proto.game.LoginRequest.prototype.setOpenaiApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string chat_model = 3;
 * @return {string}
 */
proto.game.LoginRequest.prototype.getChatModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.LoginRequest} returns this
 */
proto.game.LoginRequest.prototype.setChatModel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string realtime_model = 4;
 * @return {string}
 */
proto.game.LoginRequest.prototype.getRealtimeModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.LoginRequest} returns this
 */
proto.game.LoginRequest.prototype.setRealtimeModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string creq_id = 5;
 * @return {string}
 */
proto.game.LoginRequest.prototype.getCreqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.LoginRequest} returns this
 */
proto.game.LoginRequest.prototype.setCreqId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.LoginAck.prototype.toObject = function(opt_includeInstance) {
  return proto.game.LoginAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.LoginAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.LoginAck.toObject = function(includeInstance, msg) {
  var f, obj = {
    creqId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    audioConnKey: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.LoginAck}
 */
proto.game.LoginAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.LoginAck;
  return proto.game.LoginAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.LoginAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.LoginAck}
 */
proto.game.LoginAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreqId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudioConnKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.LoginAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.LoginAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.LoginAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.LoginAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreqId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAudioConnKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string creq_id = 1;
 * @return {string}
 */
proto.game.LoginAck.prototype.getCreqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.LoginAck} returns this
 */
proto.game.LoginAck.prototype.setCreqId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.game.LoginAck.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.LoginAck} returns this
 */
proto.game.LoginAck.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string audio_conn_key = 3;
 * @return {string}
 */
proto.game.LoginAck.prototype.getAudioConnKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.LoginAck} returns this
 */
proto.game.LoginAck.prototype.setAudioConnKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.ResetAck.prototype.toObject = function(opt_includeInstance) {
  return proto.game.ResetAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.ResetAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ResetAck.toObject = function(includeInstance, msg) {
  var f, obj = {
    creqId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.ResetAck}
 */
proto.game.ResetAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.ResetAck;
  return proto.game.ResetAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.ResetAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.ResetAck}
 */
proto.game.ResetAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreqId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.ResetAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.ResetAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.ResetAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ResetAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreqId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string creq_id = 1;
 * @return {string}
 */
proto.game.ResetAck.prototype.getCreqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.ResetAck} returns this
 */
proto.game.ResetAck.prototype.setCreqId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.game.ResetAck.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.ResetAck} returns this
 */
proto.game.ResetAck.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.ExerciseSet.prototype.toObject = function(opt_includeInstance) {
  return proto.game.ExerciseSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.ExerciseSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ExerciseSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    plannedReps: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actualReps: jspb.Message.getFieldWithDefault(msg, 2, 0),
    restTimeSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.ExerciseSet}
 */
proto.game.ExerciseSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.ExerciseSet;
  return proto.game.ExerciseSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.ExerciseSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.ExerciseSet}
 */
proto.game.ExerciseSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPlannedReps(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActualReps(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestTimeSeconds(value);
      break;
    case 4:
      var value = /** @type {!proto.game.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.ExerciseSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.ExerciseSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.ExerciseSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ExerciseSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlannedReps();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActualReps();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRestTimeSeconds();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional uint32 planned_reps = 1;
 * @return {number}
 */
proto.game.ExerciseSet.prototype.getPlannedReps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.ExerciseSet} returns this
 */
proto.game.ExerciseSet.prototype.setPlannedReps = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 actual_reps = 2;
 * @return {number}
 */
proto.game.ExerciseSet.prototype.getActualReps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.ExerciseSet} returns this
 */
proto.game.ExerciseSet.prototype.setActualReps = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 rest_time_seconds = 3;
 * @return {number}
 */
proto.game.ExerciseSet.prototype.getRestTimeSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.ExerciseSet} returns this
 */
proto.game.ExerciseSet.prototype.setRestTimeSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.game.Status}
 */
proto.game.ExerciseSet.prototype.getStatus = function() {
  return /** @type {!proto.game.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.game.Status} value
 * @return {!proto.game.ExerciseSet} returns this
 */
proto.game.ExerciseSet.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.game.Exercise.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.Exercise.prototype.toObject = function(opt_includeInstance) {
  return proto.game.Exercise.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.Exercise} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.Exercise.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    setsList: jspb.Message.toObjectList(msg.getSetsList(),
    proto.game.ExerciseSet.toObject, includeInstance),
    currentSetIndex: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.Exercise}
 */
proto.game.Exercise.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.Exercise;
  return proto.game.Exercise.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.Exercise} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.Exercise}
 */
proto.game.Exercise.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.game.ExerciseSet;
      reader.readMessage(value,proto.game.ExerciseSet.deserializeBinaryFromReader);
      msg.addSets(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentSetIndex(value);
      break;
    case 4:
      var value = /** @type {!proto.game.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.Exercise.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.Exercise.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.Exercise} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.Exercise.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.game.ExerciseSet.serializeBinaryToWriter
    );
  }
  f = message.getCurrentSetIndex();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.game.Exercise.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.Exercise} returns this
 */
proto.game.Exercise.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ExerciseSet sets = 2;
 * @return {!Array<!proto.game.ExerciseSet>}
 */
proto.game.Exercise.prototype.getSetsList = function() {
  return /** @type{!Array<!proto.game.ExerciseSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.game.ExerciseSet, 2));
};


/**
 * @param {!Array<!proto.game.ExerciseSet>} value
 * @return {!proto.game.Exercise} returns this
*/
proto.game.Exercise.prototype.setSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.game.ExerciseSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.game.ExerciseSet}
 */
proto.game.Exercise.prototype.addSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.game.ExerciseSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.game.Exercise} returns this
 */
proto.game.Exercise.prototype.clearSetsList = function() {
  return this.setSetsList([]);
};


/**
 * optional uint32 current_set_index = 3;
 * @return {number}
 */
proto.game.Exercise.prototype.getCurrentSetIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.Exercise} returns this
 */
proto.game.Exercise.prototype.setCurrentSetIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.game.Status}
 */
proto.game.Exercise.prototype.getStatus = function() {
  return /** @type {!proto.game.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.game.Status} value
 * @return {!proto.game.Exercise} returns this
 */
proto.game.Exercise.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.game.SessionData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.SessionData.prototype.toObject = function(opt_includeInstance) {
  return proto.game.SessionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.SessionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.SessionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exercisesList: jspb.Message.toObjectList(msg.getExercisesList(),
    proto.game.Exercise.toObject, includeInstance),
    currentExerciseIndex: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.SessionData}
 */
proto.game.SessionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.SessionData;
  return proto.game.SessionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.SessionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.SessionData}
 */
proto.game.SessionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.game.Exercise;
      reader.readMessage(value,proto.game.Exercise.deserializeBinaryFromReader);
      msg.addExercises(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentExerciseIndex(value);
      break;
    case 4:
      var value = /** @type {!proto.game.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.SessionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.SessionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.SessionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.SessionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExercisesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.game.Exercise.serializeBinaryToWriter
    );
  }
  f = message.getCurrentExerciseIndex();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.game.SessionData.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.game.SessionData} returns this
 */
proto.game.SessionData.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Exercise exercises = 2;
 * @return {!Array<!proto.game.Exercise>}
 */
proto.game.SessionData.prototype.getExercisesList = function() {
  return /** @type{!Array<!proto.game.Exercise>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.game.Exercise, 2));
};


/**
 * @param {!Array<!proto.game.Exercise>} value
 * @return {!proto.game.SessionData} returns this
*/
proto.game.SessionData.prototype.setExercisesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.game.Exercise=} opt_value
 * @param {number=} opt_index
 * @return {!proto.game.Exercise}
 */
proto.game.SessionData.prototype.addExercises = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.game.Exercise, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.game.SessionData} returns this
 */
proto.game.SessionData.prototype.clearExercisesList = function() {
  return this.setExercisesList([]);
};


/**
 * optional uint32 current_exercise_index = 3;
 * @return {number}
 */
proto.game.SessionData.prototype.getCurrentExerciseIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.SessionData} returns this
 */
proto.game.SessionData.prototype.setCurrentExerciseIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.game.Status}
 */
proto.game.SessionData.prototype.getStatus = function() {
  return /** @type {!proto.game.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.game.Status} value
 * @return {!proto.game.SessionData} returns this
 */
proto.game.SessionData.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.game.ExerciseSetReport.prototype.toObject = function(opt_includeInstance) {
  return proto.game.ExerciseSetReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.game.ExerciseSetReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ExerciseSetReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    exerciseIndex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    setIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    exerciseSet: (f = msg.getExerciseSet()) && proto.game.ExerciseSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.game.ExerciseSetReport}
 */
proto.game.ExerciseSetReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.game.ExerciseSetReport;
  return proto.game.ExerciseSetReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.game.ExerciseSetReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.game.ExerciseSetReport}
 */
proto.game.ExerciseSetReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExerciseIndex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSetIndex(value);
      break;
    case 3:
      var value = new proto.game.ExerciseSet;
      reader.readMessage(value,proto.game.ExerciseSet.deserializeBinaryFromReader);
      msg.setExerciseSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.game.ExerciseSetReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.game.ExerciseSetReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.game.ExerciseSetReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.game.ExerciseSetReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExerciseIndex();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSetIndex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getExerciseSet();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.game.ExerciseSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 exercise_index = 1;
 * @return {number}
 */
proto.game.ExerciseSetReport.prototype.getExerciseIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.ExerciseSetReport} returns this
 */
proto.game.ExerciseSetReport.prototype.setExerciseIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 set_index = 2;
 * @return {number}
 */
proto.game.ExerciseSetReport.prototype.getSetIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.game.ExerciseSetReport} returns this
 */
proto.game.ExerciseSetReport.prototype.setSetIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ExerciseSet exercise_set = 3;
 * @return {?proto.game.ExerciseSet}
 */
proto.game.ExerciseSetReport.prototype.getExerciseSet = function() {
  return /** @type{?proto.game.ExerciseSet} */ (
    jspb.Message.getWrapperField(this, proto.game.ExerciseSet, 3));
};


/**
 * @param {?proto.game.ExerciseSet|undefined} value
 * @return {!proto.game.ExerciseSetReport} returns this
*/
proto.game.ExerciseSetReport.prototype.setExerciseSet = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.game.ExerciseSetReport} returns this
 */
proto.game.ExerciseSetReport.prototype.clearExerciseSet = function() {
  return this.setExerciseSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.game.ExerciseSetReport.prototype.hasExerciseSet = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.game.Status = {
  NOTSTARTED: 0,
  INPROGRESS: 1,
  COMPLETED: 2
};

goog.object.extend(exports, proto.game);
