<template>
    <div class="chat-content" :class="{ 'modal': depth > 0 }" :style="{ zIndex: 1000 + depth }">
        <div class="chat-header" v-if="depth > 0">
            <h3>Trace Information (Level {{ depth }})</h3>
            <button class="close-button" @click="$emit('close')">&times;</button>
        </div>
        <div class="messages-container" ref="messagesContainer" @scroll="handleScroll">
            <ul class="chat-messages">
                <li v-for="message in messages" :key="message.id">
                    <div class="message"
                        :class="{ 'user-message': ['ToPlayer', 'AIResponse', 'PlayerInput'].includes(message.msgType), 'bot-message': !(['ToPlayer', 'AIResponse', 'PlayerInput'].includes(message.msgType)) }"
                        @mouseover="startTooltipTimer(message, $event)"
                        @mouseleave="cancelTooltipTimer">
                        <div class="message-content" v-html="renderMarkdown(message.text)"></div>
                        <div class="tags" v-if="message.tags && message.tags.length">
                            <span class="tag" v-for="tag in message.tags" :key="tag">{{ tag }}</span>
                        </div>
                        <div v-if="message.traceInfo && message.traceInfo.length" class="trace-info-icon"
                            @click.stop="$emit('openTraceInfo', message, depth)">
                            ℹ️
                        </div>
                    </div>
                </li>
            </ul>
            <button v-if="showTriggerSystem2Button" @click="$emit('triggerSystem2')" class="trigger-system-2-button">
                Trigger System 2
            </button>
            <button v-if="showScrollButton" @click="scrollToBottom" class="scroll-to-bottom">
                ⤓
            </button>
            <div v-if="isLoading" class="loading-message">
                <div class="loading-indicator"></div>
                AI is thinking...
            </div>
        </div>
        <div class="chat-input" v-if="depth === 0">
            <input type="text" v-model="newMessage" placeholder="Type your message..." @keyup.enter="sendMessage" />
            <button class="send-button" @click="sendMessage">Send</button>
        </div>
        <div v-if="tooltipVisible" class="tooltip" :style="tooltipStyle">
            <div v-html="tooltipContent"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, onMounted, nextTick, watch } from 'vue';
import { Message } from './types';
import MarkdownIt from 'markdown-it';

export default defineComponent({
    props: {
        messages: {
            type: Array as PropType<Message[]>,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        showTriggerSystem2Button: {
            type: Boolean,
            required: true,
        },
        depth: {
            type: Number,
            required: true,
        },
    },
    emits: ['openTraceInfo', 'triggerSystem2', 'close', 'sendMessage'],
    setup(props) {
        const md = new MarkdownIt();
        const newMessage = ref('');
        const messagesContainer = ref<HTMLElement | null>(null);
        const tooltipVisible = ref(false);
        const tooltipContent = ref('');
        const tooltipStyle = ref({
            top: '0px',
            left: '0px',
        });
        const tooltipTimer = ref<number | null>(null);
        const tooltipDelay = 500; // 500ms delay
        const autoScroll = ref(true);
        const showScrollButton = ref(false);

        const scrollToBottom = () => {
            nextTick(() => {
                if (messagesContainer.value) {
                    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
                    autoScroll.value = true;
                    showScrollButton.value = false;
                }
            });
        };

        const handleScroll = () => {
            if (messagesContainer.value) {
                const { scrollTop, scrollHeight, clientHeight } = messagesContainer.value;
                const bottomThreshold = scrollHeight - clientHeight - 100; // 100px threshold
                
                autoScroll.value = scrollTop >= bottomThreshold;
                showScrollButton.value = !autoScroll.value;
            }
        };

        // Watch messages for changes to trigger auto-scroll
        watch(() => props.messages, () => {
            if (autoScroll.value) {
                scrollToBottom();
            } else if (!showScrollButton.value) {
                showScrollButton.value = true;
            }
        }, { deep: true });

        onMounted(scrollToBottom);

        return { md, newMessage, messagesContainer, tooltipVisible, tooltipContent, tooltipStyle, tooltipTimer, tooltipDelay, autoScroll, showScrollButton, scrollToBottom, handleScroll };
    },
    methods: {
        renderMarkdown(content: string) {
            try {
                return this.md.render(content.trim());
            } catch (e) {
                console.error('Error rendering markdown:', e);
                return content;
            }
        },
        sendMessage() {
            if (this.newMessage.trim()) {
                this.$emit('sendMessage', this.newMessage, this.depth);
                this.newMessage = '';
            }
        },
        handleKeydown(event: KeyboardEvent) {
          if (event.key === 'Escape') {
            this.$emit('close');
          }
        },
        formatTooltipContent(message: Message): string {
            return Object.entries(message)
                .filter(([key]) => key !== 'text')
                .map(([key, value]) => {
                    let displayValue = typeof value === 'object' ? JSON.stringify(value) : value;
                    return `<div class="tooltip-row"><span class="key">${key}:</span> <span class="value">${displayValue}</span></div>`;
                })
                .join('');
        },
        startTooltipTimer(message: Message, event: MouseEvent) {
            this.cancelTooltipTimer(); // Clear any existing timer
            this.tooltipTimer = window.setTimeout(() => {
                this.showTooltip(message, event);
            }, this.tooltipDelay);
        },
        cancelTooltipTimer() {
            if (this.tooltipTimer !== null) {
                clearTimeout(this.tooltipTimer);
                this.tooltipTimer = null;
            }
            this.hideTooltip();
        },
        showTooltip(message: Message, event: MouseEvent) {
            this.tooltipContent = this.formatTooltipContent(message);
            this.tooltipVisible = true;
            this.tooltipStyle = {
                top: `${event.clientY + 10}px`,
                left: `${event.clientX + 10}px`,
            };
        },
        hideTooltip() {
            this.tooltipVisible = false;
        },
    },
    mounted() {
      window.addEventListener('keydown', this.handleKeydown);
    },
    beforeUnmount() {
      window.removeEventListener('keydown', this.handleKeydown);
    },
});
</script>

<style scoped>
.chat-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.chat-content.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-content.modal .messages-container {
    background-color: white;
    border-radius: 5px;
    width: 80%;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
}

.close-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

.messages-container {
    position: relative;
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
}

.chat-messages {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.message {
    position: relative;
    max-width: 80%;
    padding: 12px 16px;
    border-radius: 20px;
    margin-bottom: 10px;
    word-wrap: break-word;
    clear: both;
    width: max-content;
    min-width: 60px;
}

.user-message {
    align-self: flex-end;
    float: right;
    background-color: #007bff;
    color: white;
    border-radius: 20px 20px 20px 20px;
    margin-left: auto;
}

.bot-message {
    align-self: flex-start;
    float: left;
    background-color: transparent;
    color: #343A40;
    border-radius: 20px 20px 20px 20px;
}

.message-content {
    padding-right: 0;
    display: inline;
    width: auto;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 4px;
}

.tag {
    font-size: 0.75rem;
    padding: 2px 6px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.1);
    color: inherit;
}

.trace-info-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    /* Ensure icon is above message content */
}

.user-message .trace-info-icon {
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
}

.chat-input {
    display: flex;
    padding-bottom: 42px;
    border-top: 1px solid #ddd;
    background-color: #ffffff;
}

.chat-input input {
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.chat-input button {
    margin-left: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.loading-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    opacity: 0.7;
}

.loading-indicator {
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

.trigger-system-2-button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #28A745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.tooltip {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    max-width: 300px;
    overflow: auto;
    max-height: 200px;
}

.tooltip pre {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.scroll-to-bottom {
    position: fixed;
    bottom: 100px;
    right: 20px;
    background-color: var(--vscode-badge-background);
    color: var(--vscode-badge-foreground);
    border: none;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    z-index: 1000;
    opacity: 0.8;
}

.scroll-to-bottom:hover {
    opacity: 1;
    background-color: var(--vscode-badge-background);
}
</style>
