// source: audio.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.audio.AudioFileUpload', null, global);
goog.exportSymbol('proto.audio.AudioPlaybackProgressPt', null, global);
goog.exportSymbol('proto.audio.AudioPlaybackTrackChange', null, global);
goog.exportSymbol('proto.audio.AudioTranscript', null, global);
goog.exportSymbol('proto.audio.ClientConnectAck', null, global);
goog.exportSymbol('proto.audio.ClientConnectRequest', null, global);
goog.exportSymbol('proto.audio.Event', null, global);
goog.exportSymbol('proto.audio.GracefulStop', null, global);
goog.exportSymbol('proto.audio.ToAudioClient', null, global);
goog.exportSymbol('proto.audio.ToAudioClient.TypeCase', null, global);
goog.exportSymbol('proto.audio.ToAudioServer', null, global);
goog.exportSymbol('proto.audio.ToAudioServer.ContentCase', null, global);
goog.exportSymbol('proto.audio.UpstreamState', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ToAudioServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ToAudioServer.oneofGroups_);
};
goog.inherits(proto.audio.ToAudioServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ToAudioServer.displayName = 'proto.audio.ToAudioServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ToAudioClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.audio.ToAudioClient.oneofGroups_);
};
goog.inherits(proto.audio.ToAudioClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ToAudioClient.displayName = 'proto.audio.ToAudioClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ClientConnectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.ClientConnectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ClientConnectRequest.displayName = 'proto.audio.ClientConnectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.ClientConnectAck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.ClientConnectAck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.ClientConnectAck.displayName = 'proto.audio.ClientConnectAck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.GracefulStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.GracefulStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.GracefulStop.displayName = 'proto.audio.GracefulStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.AudioFileUpload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.AudioFileUpload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.AudioFileUpload.displayName = 'proto.audio.AudioFileUpload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.AudioPlaybackTrackChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.AudioPlaybackTrackChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.AudioPlaybackTrackChange.displayName = 'proto.audio.AudioPlaybackTrackChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.AudioPlaybackProgressPt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.AudioPlaybackProgressPt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.AudioPlaybackProgressPt.displayName = 'proto.audio.AudioPlaybackProgressPt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.Event.displayName = 'proto.audio.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audio.AudioTranscript = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audio.AudioTranscript, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audio.AudioTranscript.displayName = 'proto.audio.AudioTranscript';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ToAudioServer.oneofGroups_ = [[1,2,3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.audio.ToAudioServer.ContentCase = {
  CONTENT_NOT_SET: 0,
  CONNREQ: 1,
  AUDIO_REQUEST: 2,
  AUDIO_PLAYBACK_TRACK_UPDATE: 3,
  AUDIO_PLAYBACK_CANCELLED_AT: 4,
  GRACEFULLY_STOPPING_AT: 5,
  GRACEFUL_STOP_DONE: 6,
  USER_SUBMITTED_TEXT: 7,
  TOGGLE_MONOLOG_MODE: 8
};

/**
 * @return {proto.audio.ToAudioServer.ContentCase}
 */
proto.audio.ToAudioServer.prototype.getContentCase = function() {
  return /** @type {proto.audio.ToAudioServer.ContentCase} */(jspb.Message.computeOneofCase(this, proto.audio.ToAudioServer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ToAudioServer.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ToAudioServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ToAudioServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    connreq: (f = msg.getConnreq()) && proto.audio.ClientConnectRequest.toObject(includeInstance, f),
    audioRequest: (f = msg.getAudioRequest()) && proto.audio.AudioFileUpload.toObject(includeInstance, f),
    audioPlaybackTrackUpdate: (f = msg.getAudioPlaybackTrackUpdate()) && proto.audio.AudioPlaybackTrackChange.toObject(includeInstance, f),
    audioPlaybackCancelledAt: (f = msg.getAudioPlaybackCancelledAt()) && proto.audio.AudioPlaybackProgressPt.toObject(includeInstance, f),
    gracefullyStoppingAt: (f = msg.getGracefullyStoppingAt()) && proto.audio.AudioPlaybackProgressPt.toObject(includeInstance, f),
    gracefulStopDone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userSubmittedText: jspb.Message.getFieldWithDefault(msg, 7, ""),
    toggleMonologMode: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ToAudioServer}
 */
proto.audio.ToAudioServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ToAudioServer;
  return proto.audio.ToAudioServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ToAudioServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ToAudioServer}
 */
proto.audio.ToAudioServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audio.ClientConnectRequest;
      reader.readMessage(value,proto.audio.ClientConnectRequest.deserializeBinaryFromReader);
      msg.setConnreq(value);
      break;
    case 2:
      var value = new proto.audio.AudioFileUpload;
      reader.readMessage(value,proto.audio.AudioFileUpload.deserializeBinaryFromReader);
      msg.setAudioRequest(value);
      break;
    case 3:
      var value = new proto.audio.AudioPlaybackTrackChange;
      reader.readMessage(value,proto.audio.AudioPlaybackTrackChange.deserializeBinaryFromReader);
      msg.setAudioPlaybackTrackUpdate(value);
      break;
    case 4:
      var value = new proto.audio.AudioPlaybackProgressPt;
      reader.readMessage(value,proto.audio.AudioPlaybackProgressPt.deserializeBinaryFromReader);
      msg.setAudioPlaybackCancelledAt(value);
      break;
    case 5:
      var value = new proto.audio.AudioPlaybackProgressPt;
      reader.readMessage(value,proto.audio.AudioPlaybackProgressPt.deserializeBinaryFromReader);
      msg.setGracefullyStoppingAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGracefulStopDone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserSubmittedText(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToggleMonologMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ToAudioServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ToAudioServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ToAudioServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnreq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audio.ClientConnectRequest.serializeBinaryToWriter
    );
  }
  f = message.getAudioRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.AudioFileUpload.serializeBinaryToWriter
    );
  }
  f = message.getAudioPlaybackTrackUpdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.AudioPlaybackTrackChange.serializeBinaryToWriter
    );
  }
  f = message.getAudioPlaybackCancelledAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.audio.AudioPlaybackProgressPt.serializeBinaryToWriter
    );
  }
  f = message.getGracefullyStoppingAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.audio.AudioPlaybackProgressPt.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional ClientConnectRequest connreq = 1;
 * @return {?proto.audio.ClientConnectRequest}
 */
proto.audio.ToAudioServer.prototype.getConnreq = function() {
  return /** @type{?proto.audio.ClientConnectRequest} */ (
    jspb.Message.getWrapperField(this, proto.audio.ClientConnectRequest, 1));
};


/**
 * @param {?proto.audio.ClientConnectRequest|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setConnreq = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearConnreq = function() {
  return this.setConnreq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasConnreq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AudioFileUpload audio_request = 2;
 * @return {?proto.audio.AudioFileUpload}
 */
proto.audio.ToAudioServer.prototype.getAudioRequest = function() {
  return /** @type{?proto.audio.AudioFileUpload} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioFileUpload, 2));
};


/**
 * @param {?proto.audio.AudioFileUpload|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setAudioRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearAudioRequest = function() {
  return this.setAudioRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasAudioRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AudioPlaybackTrackChange audio_playback_track_update = 3;
 * @return {?proto.audio.AudioPlaybackTrackChange}
 */
proto.audio.ToAudioServer.prototype.getAudioPlaybackTrackUpdate = function() {
  return /** @type{?proto.audio.AudioPlaybackTrackChange} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioPlaybackTrackChange, 3));
};


/**
 * @param {?proto.audio.AudioPlaybackTrackChange|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setAudioPlaybackTrackUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearAudioPlaybackTrackUpdate = function() {
  return this.setAudioPlaybackTrackUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasAudioPlaybackTrackUpdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AudioPlaybackProgressPt audio_playback_cancelled_at = 4;
 * @return {?proto.audio.AudioPlaybackProgressPt}
 */
proto.audio.ToAudioServer.prototype.getAudioPlaybackCancelledAt = function() {
  return /** @type{?proto.audio.AudioPlaybackProgressPt} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioPlaybackProgressPt, 4));
};


/**
 * @param {?proto.audio.AudioPlaybackProgressPt|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setAudioPlaybackCancelledAt = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearAudioPlaybackCancelledAt = function() {
  return this.setAudioPlaybackCancelledAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasAudioPlaybackCancelledAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AudioPlaybackProgressPt gracefully_stopping_at = 5;
 * @return {?proto.audio.AudioPlaybackProgressPt}
 */
proto.audio.ToAudioServer.prototype.getGracefullyStoppingAt = function() {
  return /** @type{?proto.audio.AudioPlaybackProgressPt} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioPlaybackProgressPt, 5));
};


/**
 * @param {?proto.audio.AudioPlaybackProgressPt|undefined} value
 * @return {!proto.audio.ToAudioServer} returns this
*/
proto.audio.ToAudioServer.prototype.setGracefullyStoppingAt = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearGracefullyStoppingAt = function() {
  return this.setGracefullyStoppingAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasGracefullyStoppingAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string graceful_stop_done = 6;
 * @return {string}
 */
proto.audio.ToAudioServer.prototype.getGracefulStopDone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.setGracefulStopDone = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearGracefulStopDone = function() {
  return jspb.Message.setOneofField(this, 6, proto.audio.ToAudioServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasGracefulStopDone = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string user_submitted_text = 7;
 * @return {string}
 */
proto.audio.ToAudioServer.prototype.getUserSubmittedText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.setUserSubmittedText = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearUserSubmittedText = function() {
  return jspb.Message.setOneofField(this, 7, proto.audio.ToAudioServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasUserSubmittedText = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool toggle_monolog_mode = 8;
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.getToggleMonologMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.setToggleMonologMode = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.audio.ToAudioServer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioServer} returns this
 */
proto.audio.ToAudioServer.prototype.clearToggleMonologMode = function() {
  return jspb.Message.setOneofField(this, 8, proto.audio.ToAudioServer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioServer.prototype.hasToggleMonologMode = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.audio.ToAudioClient.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.audio.ToAudioClient.TypeCase = {
  TYPE_NOT_SET: 0,
  CONNACK: 1,
  AUDIO_RESPONSE: 2,
  AUDIO_PLAYBACK_CANCEL: 3,
  AUDIO_TRANSCRIPT: 4,
  REALTIME_CONVERSATION_STOP: 5,
  TRACK_RELAY_DONE: 6,
  UPSTREAM_STATUS: 7
};

/**
 * @return {proto.audio.ToAudioClient.TypeCase}
 */
proto.audio.ToAudioClient.prototype.getTypeCase = function() {
  return /** @type {proto.audio.ToAudioClient.TypeCase} */(jspb.Message.computeOneofCase(this, proto.audio.ToAudioClient.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ToAudioClient.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ToAudioClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ToAudioClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioClient.toObject = function(includeInstance, msg) {
  var f, obj = {
    connack: (f = msg.getConnack()) && proto.audio.ClientConnectAck.toObject(includeInstance, f),
    audioResponse: (f = msg.getAudioResponse()) && proto.audio.AudioFileUpload.toObject(includeInstance, f),
    audioPlaybackCancel: (f = msg.getAudioPlaybackCancel()) && proto.audio.Event.toObject(includeInstance, f),
    audioTranscript: (f = msg.getAudioTranscript()) && proto.audio.AudioTranscript.toObject(includeInstance, f),
    realtimeConversationStop: (f = msg.getRealtimeConversationStop()) && proto.audio.GracefulStop.toObject(includeInstance, f),
    trackRelayDone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    upstreamStatus: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ToAudioClient}
 */
proto.audio.ToAudioClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ToAudioClient;
  return proto.audio.ToAudioClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ToAudioClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ToAudioClient}
 */
proto.audio.ToAudioClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audio.ClientConnectAck;
      reader.readMessage(value,proto.audio.ClientConnectAck.deserializeBinaryFromReader);
      msg.setConnack(value);
      break;
    case 2:
      var value = new proto.audio.AudioFileUpload;
      reader.readMessage(value,proto.audio.AudioFileUpload.deserializeBinaryFromReader);
      msg.setAudioResponse(value);
      break;
    case 3:
      var value = new proto.audio.Event;
      reader.readMessage(value,proto.audio.Event.deserializeBinaryFromReader);
      msg.setAudioPlaybackCancel(value);
      break;
    case 4:
      var value = new proto.audio.AudioTranscript;
      reader.readMessage(value,proto.audio.AudioTranscript.deserializeBinaryFromReader);
      msg.setAudioTranscript(value);
      break;
    case 5:
      var value = new proto.audio.GracefulStop;
      reader.readMessage(value,proto.audio.GracefulStop.deserializeBinaryFromReader);
      msg.setRealtimeConversationStop(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackRelayDone(value);
      break;
    case 7:
      var value = /** @type {!proto.audio.UpstreamState} */ (reader.readEnum());
      msg.setUpstreamStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ToAudioClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ToAudioClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ToAudioClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ToAudioClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnack();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audio.ClientConnectAck.serializeBinaryToWriter
    );
  }
  f = message.getAudioResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.audio.AudioFileUpload.serializeBinaryToWriter
    );
  }
  f = message.getAudioPlaybackCancel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audio.Event.serializeBinaryToWriter
    );
  }
  f = message.getAudioTranscript();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.audio.AudioTranscript.serializeBinaryToWriter
    );
  }
  f = message.getRealtimeConversationStop();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.audio.GracefulStop.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {!proto.audio.UpstreamState} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional ClientConnectAck connack = 1;
 * @return {?proto.audio.ClientConnectAck}
 */
proto.audio.ToAudioClient.prototype.getConnack = function() {
  return /** @type{?proto.audio.ClientConnectAck} */ (
    jspb.Message.getWrapperField(this, proto.audio.ClientConnectAck, 1));
};


/**
 * @param {?proto.audio.ClientConnectAck|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setConnack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearConnack = function() {
  return this.setConnack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasConnack = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AudioFileUpload audio_response = 2;
 * @return {?proto.audio.AudioFileUpload}
 */
proto.audio.ToAudioClient.prototype.getAudioResponse = function() {
  return /** @type{?proto.audio.AudioFileUpload} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioFileUpload, 2));
};


/**
 * @param {?proto.audio.AudioFileUpload|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setAudioResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearAudioResponse = function() {
  return this.setAudioResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasAudioResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Event audio_playback_cancel = 3;
 * @return {?proto.audio.Event}
 */
proto.audio.ToAudioClient.prototype.getAudioPlaybackCancel = function() {
  return /** @type{?proto.audio.Event} */ (
    jspb.Message.getWrapperField(this, proto.audio.Event, 3));
};


/**
 * @param {?proto.audio.Event|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setAudioPlaybackCancel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearAudioPlaybackCancel = function() {
  return this.setAudioPlaybackCancel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasAudioPlaybackCancel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AudioTranscript audio_transcript = 4;
 * @return {?proto.audio.AudioTranscript}
 */
proto.audio.ToAudioClient.prototype.getAudioTranscript = function() {
  return /** @type{?proto.audio.AudioTranscript} */ (
    jspb.Message.getWrapperField(this, proto.audio.AudioTranscript, 4));
};


/**
 * @param {?proto.audio.AudioTranscript|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setAudioTranscript = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearAudioTranscript = function() {
  return this.setAudioTranscript(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasAudioTranscript = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GracefulStop realtime_conversation_stop = 5;
 * @return {?proto.audio.GracefulStop}
 */
proto.audio.ToAudioClient.prototype.getRealtimeConversationStop = function() {
  return /** @type{?proto.audio.GracefulStop} */ (
    jspb.Message.getWrapperField(this, proto.audio.GracefulStop, 5));
};


/**
 * @param {?proto.audio.GracefulStop|undefined} value
 * @return {!proto.audio.ToAudioClient} returns this
*/
proto.audio.ToAudioClient.prototype.setRealtimeConversationStop = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearRealtimeConversationStop = function() {
  return this.setRealtimeConversationStop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasRealtimeConversationStop = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string track_relay_done = 6;
 * @return {string}
 */
proto.audio.ToAudioClient.prototype.getTrackRelayDone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.setTrackRelayDone = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearTrackRelayDone = function() {
  return jspb.Message.setOneofField(this, 6, proto.audio.ToAudioClient.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasTrackRelayDone = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UpstreamState upstream_status = 7;
 * @return {!proto.audio.UpstreamState}
 */
proto.audio.ToAudioClient.prototype.getUpstreamStatus = function() {
  return /** @type {!proto.audio.UpstreamState} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.audio.UpstreamState} value
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.setUpstreamStatus = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.audio.ToAudioClient.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.audio.ToAudioClient} returns this
 */
proto.audio.ToAudioClient.prototype.clearUpstreamStatus = function() {
  return jspb.Message.setOneofField(this, 7, proto.audio.ToAudioClient.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audio.ToAudioClient.prototype.hasUpstreamStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ClientConnectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ClientConnectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ClientConnectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userIdent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    openAiApiKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    openAiChatModel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    conversationNumber: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ClientConnectRequest}
 */
proto.audio.ClientConnectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ClientConnectRequest;
  return proto.audio.ClientConnectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ClientConnectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ClientConnectRequest}
 */
proto.audio.ClientConnectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIdent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiApiKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiChatModel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setConversationNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ClientConnectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ClientConnectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ClientConnectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserIdent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOpenAiApiKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOpenAiChatModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConversationNumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string auth_key = 1;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getAuthKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setAuthKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_ident = 2;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getUserIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setUserIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string open_ai_api_key = 3;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getOpenAiApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setOpenAiApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string open_ai_chat_model = 4;
 * @return {string}
 */
proto.audio.ClientConnectRequest.prototype.getOpenAiChatModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setOpenAiChatModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 conversation_number = 5;
 * @return {number}
 */
proto.audio.ClientConnectRequest.prototype.getConversationNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.ClientConnectRequest} returns this
 */
proto.audio.ClientConnectRequest.prototype.setConversationNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.ClientConnectAck.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.ClientConnectAck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.ClientConnectAck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectAck.toObject = function(includeInstance, msg) {
  var f, obj = {
    telltale: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.ClientConnectAck}
 */
proto.audio.ClientConnectAck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.ClientConnectAck;
  return proto.audio.ClientConnectAck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.ClientConnectAck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.ClientConnectAck}
 */
proto.audio.ClientConnectAck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelltale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.ClientConnectAck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.ClientConnectAck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.ClientConnectAck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.ClientConnectAck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTelltale();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string telltale = 1;
 * @return {string}
 */
proto.audio.ClientConnectAck.prototype.getTelltale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.ClientConnectAck} returns this
 */
proto.audio.ClientConnectAck.prototype.setTelltale = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.GracefulStop.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.GracefulStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.GracefulStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.GracefulStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    sreqId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maxPlaybackCompletionTmMs: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.GracefulStop}
 */
proto.audio.GracefulStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.GracefulStop;
  return proto.audio.GracefulStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.GracefulStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.GracefulStop}
 */
proto.audio.GracefulStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSreqId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxPlaybackCompletionTmMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.GracefulStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.GracefulStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.GracefulStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.GracefulStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSreqId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaxPlaybackCompletionTmMs();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string sreq_id = 1;
 * @return {string}
 */
proto.audio.GracefulStop.prototype.getSreqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.GracefulStop} returns this
 */
proto.audio.GracefulStop.prototype.setSreqId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 max_playback_completion_tm_ms = 2;
 * @return {number}
 */
proto.audio.GracefulStop.prototype.getMaxPlaybackCompletionTmMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.GracefulStop} returns this
 */
proto.audio.GracefulStop.prototype.setMaxPlaybackCompletionTmMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.AudioFileUpload.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.AudioFileUpload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.AudioFileUpload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioFileUpload.toObject = function(includeInstance, msg) {
  var f, obj = {
    audio: msg.getAudio_asB64(),
    audioType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sequenceNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    phaseNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    audioDurationMs: jspb.Message.getFieldWithDefault(msg, 10, 0),
    userIdent: jspb.Message.getFieldWithDefault(msg, 7, ""),
    openAiApiKey: jspb.Message.getFieldWithDefault(msg, 8, ""),
    openAiChatModel: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.AudioFileUpload}
 */
proto.audio.AudioFileUpload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.AudioFileUpload;
  return proto.audio.AudioFileUpload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.AudioFileUpload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.AudioFileUpload}
 */
proto.audio.AudioFileUpload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAudio(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudioType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSequenceNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPhaseNumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAudioDurationMs(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIdent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiApiKey(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpenAiChatModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.AudioFileUpload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.AudioFileUpload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.AudioFileUpload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioFileUpload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudio_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAudioType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSequenceNumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPhaseNumber();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAudioDurationMs();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getUserIdent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOpenAiApiKey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOpenAiChatModel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional bytes audio = 1;
 * @return {!(string|Uint8Array)}
 */
proto.audio.AudioFileUpload.prototype.getAudio = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes audio = 1;
 * This is a type-conversion wrapper around `getAudio()`
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getAudio_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAudio()));
};


/**
 * optional bytes audio = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAudio()`
 * @return {!Uint8Array}
 */
proto.audio.AudioFileUpload.prototype.getAudio_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAudio()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setAudio = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string audio_type = 2;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getAudioType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setAudioType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string item_id = 3;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_name = 4;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 sequence_number = 5;
 * @return {number}
 */
proto.audio.AudioFileUpload.prototype.getSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 phase_number = 6;
 * @return {number}
 */
proto.audio.AudioFileUpload.prototype.getPhaseNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setPhaseNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 audio_duration_ms = 10;
 * @return {number}
 */
proto.audio.AudioFileUpload.prototype.getAudioDurationMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setAudioDurationMs = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string user_ident = 7;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getUserIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setUserIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string open_ai_api_key = 8;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getOpenAiApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setOpenAiApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string open_ai_chat_model = 9;
 * @return {string}
 */
proto.audio.AudioFileUpload.prototype.getOpenAiChatModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioFileUpload} returns this
 */
proto.audio.AudioFileUpload.prototype.setOpenAiChatModel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.AudioPlaybackTrackChange.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.AudioPlaybackTrackChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.AudioPlaybackTrackChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioPlaybackTrackChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldTrackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newTrackId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.AudioPlaybackTrackChange}
 */
proto.audio.AudioPlaybackTrackChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.AudioPlaybackTrackChange;
  return proto.audio.AudioPlaybackTrackChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.AudioPlaybackTrackChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.AudioPlaybackTrackChange}
 */
proto.audio.AudioPlaybackTrackChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewTrackId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.AudioPlaybackTrackChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.AudioPlaybackTrackChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.AudioPlaybackTrackChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioPlaybackTrackChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewTrackId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string old_track_id = 1;
 * @return {string}
 */
proto.audio.AudioPlaybackTrackChange.prototype.getOldTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioPlaybackTrackChange} returns this
 */
proto.audio.AudioPlaybackTrackChange.prototype.setOldTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_track_id = 2;
 * @return {string}
 */
proto.audio.AudioPlaybackTrackChange.prototype.getNewTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioPlaybackTrackChange} returns this
 */
proto.audio.AudioPlaybackTrackChange.prototype.setNewTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.AudioPlaybackProgressPt.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.AudioPlaybackProgressPt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.AudioPlaybackProgressPt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioPlaybackProgressPt.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offsetMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sreqId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.AudioPlaybackProgressPt}
 */
proto.audio.AudioPlaybackProgressPt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.AudioPlaybackProgressPt;
  return proto.audio.AudioPlaybackProgressPt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.AudioPlaybackProgressPt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.AudioPlaybackProgressPt}
 */
proto.audio.AudioPlaybackProgressPt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffsetMs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSreqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.AudioPlaybackProgressPt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.AudioPlaybackProgressPt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.AudioPlaybackProgressPt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioPlaybackProgressPt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOffsetMs();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSreqId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.audio.AudioPlaybackProgressPt.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioPlaybackProgressPt} returns this
 */
proto.audio.AudioPlaybackProgressPt.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 offset_ms = 2;
 * @return {number}
 */
proto.audio.AudioPlaybackProgressPt.prototype.getOffsetMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.audio.AudioPlaybackProgressPt} returns this
 */
proto.audio.AudioPlaybackProgressPt.prototype.setOffsetMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sreq_id = 3;
 * @return {string}
 */
proto.audio.AudioPlaybackProgressPt.prototype.getSreqId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioPlaybackProgressPt} returns this
 */
proto.audio.AudioPlaybackProgressPt.prototype.setSreqId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.Event}
 */
proto.audio.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.Event;
  return proto.audio.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.Event}
 */
proto.audio.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string info = 1;
 * @return {string}
 */
proto.audio.Event.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.Event} returns this
 */
proto.audio.Event.prototype.setInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audio.AudioTranscript.prototype.toObject = function(opt_includeInstance) {
  return proto.audio.AudioTranscript.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audio.AudioTranscript} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioTranscript.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audio.AudioTranscript}
 */
proto.audio.AudioTranscript.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audio.AudioTranscript;
  return proto.audio.AudioTranscript.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audio.AudioTranscript} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audio.AudioTranscript}
 */
proto.audio.AudioTranscript.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audio.AudioTranscript.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audio.AudioTranscript.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audio.AudioTranscript} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audio.AudioTranscript.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string source = 1;
 * @return {string}
 */
proto.audio.AudioTranscript.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioTranscript} returns this
 */
proto.audio.AudioTranscript.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.audio.AudioTranscript.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioTranscript} returns this
 */
proto.audio.AudioTranscript.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string item_id = 3;
 * @return {string}
 */
proto.audio.AudioTranscript.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audio.AudioTranscript} returns this
 */
proto.audio.AudioTranscript.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.audio.UpstreamState = {
  NO_CONNECTION: 0,
  CONN_BIDIR: 1,
  CONN_MONOLOG: 2
};

goog.object.extend(exports, proto.audio);
