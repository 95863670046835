<template>
    <div class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
            <h2>Settings</h2>
            <div class="setting">
                <label for="openaiToken">OpenAI Token:</label>
                <input type="text" id="openaiToken" v-model="localSettings.openAiApiKey">
            </div>
            <div class="setting">
                <label for="chatModel">Chat Model:</label>
                <select id="chatModel" v-model="localSettings.chatModel">
                    <option value="gpt-4o">GPT-4o</option>
                    <option value="gpt-4o-2024-08-06">gpt-4o-2024-08-06</option>
                    <option value="gpt-4o-mini">GPT-4o-mini</option>
                    <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                </select>
            </div>
            <div class="setting">
                <label for="realtimeModel">Realtime Model:</label>
                <select id="realtimeModel" v-model="localSettings.realtimeModel">
                    <option value="gpt-4o-realtime-preview-2024-10-01">gpt-4o-realtime-preview-2024-10-01</option>
                    <option value="gpt-4o-realtime-preview-2024-12-17">gpt-4o-realtime-preview-2024-12-17</option>
                    <option value="gpt-4o-mini-realtime-preview-2024-12-17">gpt-4o-mini-realtime-preview-2024-12-17</option>
                </select>
            </div>
            <div class="modal-buttons">
                <button @click="saveSettings">Save</button>
                <button @click="closeModal">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Settings } from './types';

export default defineComponent({
    name: 'SettingsModal',
    emits: ['close', 'save'],
    setup(props, { emit }) {
        const localSettings = ref<Settings>({
            openAiApiKey: '',
            chatModel: 'gpt-4o-mini',
            realtimeModel: 'gpt-4o-realtime-preview-2024-10-01',
        });

        onMounted(() => {
            const savedSettings = localStorage.getItem('settings');
            if (savedSettings) {
                localSettings.value = JSON.parse(savedSettings);
            }
        });

        const saveSettings = () => {
            emit('save', localSettings.value);
        };

        const closeModal = () => {
            emit('close');
        };

        return {
            localSettings,
            saveSettings,
            closeModal,
        };
    },
});
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    width: 400px;
    overflow-y: auto;
    z-index: 2001;
}

.setting {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input,
select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}
</style>
