import {
  ToAutomabServer,
  ToAutomabClient,
  ClientMessage,
  ServerMessage,
  SessionData
} from '../../proto-gen-ref/automab_pb';
import { WebSocketManager } from '../WebSocketManager';
import { ToastPluginApi } from 'vue-toast-notification';

export interface IAutomabService extends WebSocketManager {
  sendMessage(type: string, payload: string): void;
  onMessage(callback: (message: ServerMessage) => void): void;
  onSessionUpdate(callback: (sessionData: string) => void): void;
}

export class AutomabService extends WebSocketManager implements IAutomabService {
  private static instance: AutomabService | null = null;
  private messageCallbacks: Array<(message: ServerMessage) => void> = [];
  private sessionCallbacks: Array<(sessionData: string) => void> = [];

  private constructor(hostPortNum: number, toast: ToastPluginApi, userToken: string) {
    super(hostPortNum, toast, userToken);
  }

  public static initialize(hostPortNum: number, toast: ToastPluginApi, userToken: string): AutomabService {
    if (this.instance) {
      throw new Error('AutomabService already initialized');
    }
    this.instance = new AutomabService(hostPortNum, toast, userToken);
    return this.instance;
  }

  public static async getInstance(): Promise<AutomabService> {
    if (!this.instance) {
      throw new Error('AutomabService not initialized');
    }
    if (!this.instance.isConnected()) {
      await this.instance.connect();
    }
    return this.instance;
  }

  public onMessage(callback: (message: ServerMessage) => void): void {
    this.messageCallbacks.push(callback);
  }

  public onSessionUpdate(callback: (sessionData: string) => void): void {
    this.sessionCallbacks.push(callback);
  }

  protected handleMessage(event: MessageEvent): void {
    const message = ToAutomabClient.deserializeBinary(new Uint8Array(event.data));

    this.handleServerMessage(message.getMessage());
    this.handleSessionData(message.getSession());
  }

  private handleServerMessage(serverMessage: ServerMessage | undefined): void {
    if (!serverMessage) return;

    console.log('Received message type:', serverMessage.getType());
    this.messageCallbacks.forEach(callback => {
      callback(serverMessage);
    });
  }

  private handleSessionData(sessionData: SessionData | undefined): void {
    if (!sessionData) return;

    let htmlSummary = `<div class="session-summary">`;
    const status = this.statusToString(sessionData.getStatus());
    htmlSummary += `<p>Session Date: ${sessionData.getDate()}</p>`;
    htmlSummary += `<p>Session Status: ${status}</p>`;

    sessionData.getExperimentsList().forEach((experiment, index) => {
      const experimentStatus = this.statusToString(experiment.getStatus());
      htmlSummary += `<div class="experiment-summary">`;
      htmlSummary += `<h4>Experiment ${index + 1}:</h4>`;
      htmlSummary += `<p>Type: ${experiment.getType()}</p>`;
      htmlSummary += `<p>Status: ${experimentStatus}</p>`;
      htmlSummary += `<p>Objective: ${experiment.getObjective()}</p>`;
      htmlSummary += `<p>Planned Method: ${experiment.getPlannedmethod()}</p>`;
      htmlSummary += `<p>Pre-run Notes: ${experiment.getPrerunnotes()}</p>`;
      htmlSummary += `<p>Current Run Index: ${experiment.getCurrentrunindex()}</p>`;

      htmlSummary += `<details>`;
      htmlSummary += `<summary>Automation Script</summary>`;
      htmlSummary += `<pre>${experiment.getAutomationscript()}</pre>`;
      htmlSummary += `</details>`;

      if (experiment.hasSampleinfo()) {
        const sampleInfo = experiment.getSampleinfo()!;
        htmlSummary += `<div class="sample-info">`;
        htmlSummary += `<p>Sample Info:</p>`;
        htmlSummary += `<p>Name: ${sampleInfo.getName()}</p>`;
        htmlSummary += `<p>Source: ${sampleInfo.getSource()}</p>`;
        htmlSummary += `<p>Concentration: ${sampleInfo.getConcentration()}</p>`;
        htmlSummary += `<p>Preparation Steps: ${sampleInfo.getPreparationsteps()}</p>`;
        htmlSummary += `</div>`;
      }

      experiment.getReagentsplannedList().forEach((reagent, reagentIndex) => {
        htmlSummary += `<div class="reagent-planned">`;
        htmlSummary += `<p>Reagent Planned ${reagentIndex + 1}:</p>`;
        htmlSummary += `<p>Name: ${reagent.getName()}</p>`;
        htmlSummary += `<p>Concentration: ${reagent.getConcentration()}</p>`;
        htmlSummary += `<p>Role: ${reagent.getRole()}</p>`;
        htmlSummary += `</div>`;
      });

      htmlSummary += `</div>`;
    });

    htmlSummary += `<p>Current Experiment: ${sessionData.getCurrentexperimentindex() + 1}</p>`;
    htmlSummary += `</div>`;

    this.sessionCallbacks.forEach(callback => {
      callback(htmlSummary);
    });
  }

  // Helper method to convert status numbers to strings
  private statusToString(status: number): string {
    switch (status) {
      case 0:
        return 'Not Started';
      case 1:
        return 'In Progress';
      case 2:
        return 'Completed';
      case 3:
        return 'Failed';
      default:
        return 'Unknown';
    }
  }

  protected sendConnectRequest(): boolean {
    return true; // because we don't need to ack, so we want the ourstanding promise to resolve right awaqy
  }

  protected onCleanup(): void {
    // Implement any cleanup specific to AutomabService
  }

  public async sendMessage(type: string, payload: string): Promise<void> {
    try {
      if (!this.isConnected()) {
        await this.connect();
      }
    } catch (error) {
      console.error('Failed to connect to AutomabService:', error);
      //throw error;
    }

    const clientMessage = new ClientMessage();
    clientMessage.setType(type);
    clientMessage.setPayload(payload);

    const request = new ToAutomabServer();
    request.setMessage(clientMessage);

    this.wsConnection!.send(request.serializeBinary());
  }
} 